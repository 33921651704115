import LazyImage from "react-lazy-blur-image";
import { useNavigate } from "react-router-dom";
const Card = (props: any) => {
  const { item, key } = props;
  const navigate = useNavigate();
  const handleClick = (item: any) => {
    navigate(`/blog-detail/${item?.id}`);
  };

  const convertDate = (initialDateStr: any) => {
    const initialDate = new Date(initialDateStr);
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      initialDate
    );
    const year = initialDate.getFullYear();
    return `${initialDate.getDate()} ${month} ${year}`;
  };

  return (
    <>
      <div className="col-md-4 col-xl-4 mb-4" key={key}>
        <div className="card position-relative rounded-4 border-0 blog">
          <LazyImage
            uri={item.blogImage}
            placeholder={"Blog"}
            render={(src: any, style: any) => (
              <img
                src={src}
                className="card-img-top rounded-top-4 img-fluid "
                alt="BannerImage"
                loading="lazy"
              />
            )}
          />
          <div className="card-body rounded-bottom-4 border pb-1">
            <div className="card-text mb-1">
              <span className=" text-capitalize d-md-inline-block text-6941C6 text-truncate font-14 fw-600">
                {item?.name} • {convertDate(item?.date)}
              </span>
            </div>
            <div className="card-title font-24 fw-600 position-relative" onClick={() => {
              handleClick(item);
            }}>
              <div className="blog-title-font cursor-pointer text-101828 lh-32 text-truncate-card pe-2">
                {item?.title}
              </div>
              <div
                className="position-absolute top-0 end-0 cursor-pointer"
                onClick={() => {
                  handleClick(item);
                }}
              >
                <img src="/media/assets/svg/Blog-Icon.svg" alt="Blog-Icon" className="img-fluid" loading="lazy" />
              </div>
            </div>
            <div className="card-text mb-2">
              <p className="font-16 text-667085 text-justify mb-0 blog-card-font fw-400 text-truncate-card">
                {item?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Card;
