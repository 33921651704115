/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import showConfirmationDialog from "../../helper/showConfirmationDialogUtility";
import { useAuth } from "../../module/auth/core/Auth";
import { Icon } from "@iconify/react";
import { GetInitailCharaterClass } from "../../services/utility";
import { toAbsoluteUrl } from "../../helper";
import { USER_DEFAULT_IMAGE_PATH } from "../../shared/constants";
import { WEB_ENDPOINT } from "../../shared/apiConfig";

const Header = () => {
  const { auth, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleRedirect = (path: any) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  function clsx(...classNames: (string | undefined)[]): string {
    return classNames.filter(Boolean).join(" ");
  }

  const ConfirmationModal = async () => {
    const confirmed = await showConfirmationDialog(
      "Confirmation",
      "Are you sure you want to Log out?",
      "Log Out"
    );
    if (confirmed) {
      logout();
      navigate('/');
    }
  };
  return (
    <>
      <header>
        <Navbar
          expand="lg"
          className="bg-header-light position-fixed top-0 start-0 end-0  shadow-sm "
          style={{ zIndex: '100' }}
        >
          <Container>
            <Navbar.Brand
              as={Link}
              to="/"
              className={`${location.pathname === "/" && "active"}`}
            >
              <div className="text-default-color bebas-neue-font mb-0">
                <img src={`${WEB_ENDPOINT}/Move-logo-BG-transparent.svg`} className="img-fluid" alt="header-logo"
                  width={151} loading="lazy" />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="text-dark border-0"
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="menu menu-3"
            >
              <Nav className="ms-auto nav text-start gap-lg-4">
                {!auth && (
                  <>
                    {/* <Nav.Link
                      as={Link}
                      to="/"
                      className={`fw-500  NavLink ${
                        location.pathname === "/" && "active"
                      }`}
                      onClick={() => handleRedirect("/")}
                    >
                      Home
                    </Nav.Link> */}
                    <Nav.Link
                      as={Link}
                      to="/about-us"
                      className={`fw-d-din-400 text-black NavLink ${location.pathname === "/about-us" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        ABOUT US
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/events"
                      className={`fw-d-din-400 text-black NavLink ${location.pathname === "/events" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        EVENTS
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/blogs"
                      className={`fw-d-din-400 text-black NavLink ${location.pathname === "/blogs" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        BLOGS
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/faq"
                      className={`fw-d-din-400 text-black NavLink ${location.pathname === "/faq" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        FAQ'S
                      </span>
                    </Nav.Link>
                    {/* <Nav.Link
                      as={Link}
                      to="/testimonials"
                      className={`fw-d-din-400 text-black NavLink ${location.pathname === "/testimonials" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        TESTIMONIALS
                      </span>
                    </Nav.Link> */}
                    <Nav.Link
                      as={Link}
                      to="/contact-us"
                      className={`fw-d-din-400 text-black NavLink ${location.pathname === "/contact-us" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        CONTACT
                      </span>
                    </Nav.Link>
                  </>
                )}

                {auth && (
                  <>
                    <Nav.Link
                      as={Link}
                      to="/events"
                      className={`fw-d-din-400  NavLink d-lg-flex align-items-center ${location.pathname === "/events" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        EVENTS
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/participations"
                      className={`fw-d-din-400 NavLink d-lg-flex align-items-center ${location.pathname === "/participations" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        MY EVENTS
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/activity-history"
                      className={`fw-d-din-400  NavLink d-lg-flex align-items-center ${location.pathname === "/activity-history" && "active"
                        }`}
                      onClick={() => handleRedirect("/")}
                    >
                      <span className="font-18">
                        HISTORY
                      </span>
                    </Nav.Link>
                    <NavDropdown
                      title={
                        <>
                          <span className="d-sm-block  d-md-block d-lg-none">
                            {auth?.data?.firstName}
                          </span>
                          {auth?.data?.image ? (
                            <img
                              className="rounded-circle d-none d-lg-inline-block img-fluid"
                              src={auth?.data?.image}
                              onError={(ele) => {
                                ele.currentTarget.src = toAbsoluteUrl(
                                  USER_DEFAULT_IMAGE_PATH
                                );
                              }}
                              alt="User Image"
                              width={40}
                              height={40}
                              loading="lazy"
                            />
                          ) : (
                            <div
                              className="rounded-circle d-none d-lg-inline-block  bg-light  text-center"
                              style={{
                                width: 40,
                                height: 40,
                                lineHeight: "40px",
                              }}
                            >
                              <span
                                className={clsx(
                                  "symbol-label",
                                  "fs-2x",
                                  "fw-semibold",
                                  "bg-light",
                                  GetInitailCharaterClass(
                                    auth?.data?.firstName
                                      ? auth?.data?.firstName
                                        ?.charAt(0)
                                        ?.toUpperCase()
                                      : ""
                                  )
                                )}
                              >
                                {auth?.data?.firstName.charAt(0).toUpperCase()}
                              </span>
                            </div>
                          )}
                        </>
                      }
                      id="basic-nav-dropdown"
                      align={"end"}
                      className="profile-nav-dropdown"
                    >
                      <NavDropdown.Header className="font-18 font-5E5E5E fw-400 d-none d-lg-inline-block w-100">
                        Hi {auth?.data?.firstName},
                        <NavDropdown.Divider />
                      </NavDropdown.Header>

                      <NavDropdown.Item as={Link} to={"/profile"} className="d-flex align-items-center">
                        <Icon
                          icon="material-symbols:person-outline"
                          color="#5E5E5E"
                          height={19}
                        />
                        <span className="mx-2 font-5E5E5E fw-400 font-16">Profile</span>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={"/transaction-history"} className="d-flex align-items-center">
                        <Icon icon="material-symbols:history" height={19} color="#5E5E5E" />
                        <span className="mx-2 font-5E5E5E fw-400 font-16">Transaction History</span>
                      </NavDropdown.Item>

                      <NavDropdown.Item onClick={ConfirmationModal} className="d-flex align-items-center">
                        <Icon
                          icon="ion:log-out-outline"
                          height={19}
                          className="text-danger"
                        />
                        <span className="mx-2 fw-400 text-danger font-16">Logout</span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}

                {!auth && (
                  <Nav.Link
                    as={Link}
                    to="/auth"
                    className="btnNavLink px-3 py-2 text-black btn btn-getapp rounded-pill fw-600"
                  >
                    Log In
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
