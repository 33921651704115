import React, { useState, useEffect } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "@iconify/react";

export const MobileSlider: React.FC = () => {
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius] = useState(2);
  const [showNavigation, setShowNavigation] = useState(true);

  const slides = [
    {
      key: uuidv4(),
      content: (
        <img
          src="./media/assets/Home/slider-get-started.png"
          alt="slider-get-started"
          loading="lazy"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src="./media/assets/Home/slider-onboard.png"
          alt="slider-onboard"
          loading="lazy"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img src="./media/assets/Home/slider-home.png" alt="slider-home" loading="lazy" />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src="./media/assets/Home/slider-event-details.png"
          alt="slider-event-details"
          loading="lazy"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src="./media/assets/Home/slider-cycling-activity-details.png"
          alt="slider-cycling-activity-details"
          className="img-fluid"
          loading="lazy"
        />
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (goToSlide + 1) % slides?.length;
      setGoToSlide(newIndex);
    }, 3000);

    const handleResize = () => {
      setShowNavigation(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, [goToSlide, slides?.length]);

  const handleNavigateLeft = () => {
    setGoToSlide((prevSlide) => (prevSlide === 0 ? slides?.length - 1 : prevSlide - 1));
  };

  const handleNavigateRight = () => {
    setGoToSlide((prevSlide) => (prevSlide === slides?.length - 1 ? 0 : prevSlide + 1));
  };

  const handleSwipe = (delta: number) => {
    let newIndex = goToSlide + delta;
    if (newIndex < 0) {
      newIndex = slides.length - 1;
    } else if (newIndex >= slides?.length) {
      newIndex = 0;
    }
    setGoToSlide(newIndex);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchStartX = e.touches[0].clientX;
    const touchStartY = e.touches[0].clientY;

    const handleTouchMove = (moveEvent: TouchEvent) => {
      const deltaX = moveEvent.touches[0].clientX - touchStartX;
      const deltaY = moveEvent.touches[0].clientY - touchStartY;
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // moveEvent.preventDefault();
        if (deltaX > 0) {
          handleSwipe(-1);
        } else {
          handleSwipe(1);
        }
      }
      document.removeEventListener("touchmove", handleTouchMove);
    };

    document.addEventListener("touchmove", handleTouchMove);
  };




  return (
    <main className="bg-header-light py-5">
      <section>
        <div className="container">
          <div className="row">
            <div
              className="col-12"
              style={{
                width: "80%",
                height: "500px",
                margin: "0 auto",
                position: "relative",
              }}


            >

              <Carousel
                slides={slides}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                animationConfig={{ tension: 50, friction: 10 }}
                // { tension:900, friction: 100 }
                goToSlideDelay={1000}
                showNavigation={false}
              />

              <img
                src="./media/assets/Home/slider-phone-mockup.png"
                alt="Event Details"
                className="img-fluid slider-main"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 10,
                  height: "525px",
                  filter: "drop-shadow(5px 5px 5px rgba(0,0,0, 0.5))",
                }}
                onTouchStart={handleTouchStart}
                loading="lazy"
              />


              {showNavigation && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "-8%",
                    transform: "translateY(-50%)",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "116%",
                    zIndex: 20,
                  }}
                >
                  <button
                    onClick={handleNavigateLeft}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "40px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <Icon
                      icon={"icon-park-solid:left-c"}
                      className="text-dark"
                    />
                  </button>
                  <button
                    onClick={handleNavigateRight}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "40px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <Icon
                      icon={"icon-park-solid:right-c"}
                      className="text-dark"
                    />
                  </button>
                </div>
              )}


              {showNavigation && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  {slides.map((slide, index) => (
                    <span
                      key={slide.key}
                      onClick={() => setGoToSlide(index)}
                      style={{
                        cursor: "pointer",
                        marginInline: "3px",
                        color: index === goToSlide ? "#000" : "#ccc",
                      }}
                    >
                      ●
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
