import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EmailPage: React.FC = () => {
  return (
    <>
      <main>
        <section className="my-5 section-header-padding">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="text-center mb-4 bebas-neue-font">
                  <h1 className="text-uppercase text-default-color">
                    up comings events
                  </h1>
                </div>
              </Col>
              <Col lg={10}>
                <div className="text-center plus-jakarta-sans-font">
                  <p className="mb-0">
                    Experience the magic of our Special Christmas Events!
                    Interested? Announce your name for running, cycling, and
                    walking festivities. Click 'Join Now' to register and secure
                    your spot in the holiday celebration!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="mb-3">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col lg={6} className="col-12 mb-4">
                <div className="image-container">
                  <div className="overlay rounded-4"></div>
                  <div
                    className="d-flex transparent-bg align-items-center position-absolute top-0 top-right mx-4 my-4 border border-white rounded-pill px-3 py-1 gap-2 mobile-cycle-only"
                    style={{ fontWeight: "600" }}
                  >
                    <img
                      src="/media/assets/svg/directions-bike.svg"
                      alt="bike"
                      height={20}
                      loading="lazy"
                    />
                    <p className="text plus-jakarta-sans-font fs-6 text-uppercase text-dark my-0">
                      cycling
                    </p>
                  </div>
                  <div
                    className="d-lg-none transparent-bg align-items-center position-absolute top-0 end-0 mx-4 my-4 border border-white rounded-circle px-2 py-1 mobile-share-only"
                    style={{ fontWeight: "600" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-share-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                    </svg>
                  </div>
                  <Row>
                    <div className="col-auto position-absolute bottom-0 start-0 ms-2 mb-3">
                      <h2 className="text-white text-start bebas-neue-font mb-0">
                        Hyper cycling
                      </h2>
                      <p className="text-white text-start fs-6 plus-jakarta-sans-font mb-0">
                        <span className="text-default-color">254 people</span>{" "}
                        has already started this event
                      </p>
                    </div>
                    <div className="col-auto position-absolute bottom-0 end-0 me-lg-2 mb-3">
                      <Button
                        type="button"
                        className="rounded-pill btn-default fs-5 fw-bold border-white"
                      >
                        Join Now
                      </Button>
                    </div>
                  </Row>
                  <div className="geeks rounded-4">
                    <img
                      src="media/assets/events/hyper-cycling.jpg"
                      className="img-fluid rounded-4"
                      alt="cycle"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} className="col-12 mb-4">
                <div className="image-container">
                  <div className="overlay rounded-4"></div>
                  <div
                    className="d-flex transparent-bg align-items-center position-absolute top-0 top-right mx-4 my-4 border border-white rounded-pill px-3 py-1 gap-2 mobile-cycle-only"
                    style={{ fontWeight: "600" }}
                  >
                    <img
                      src="/media/assets/svg/directions-bike.svg"
                      height={20}
                      className="img-fluid"
                      alt="bikeride"
                      loading="lazy"
                    />
                    <p className="text plus-jakarta-sans-font fs-6 text-uppercase text-dark my-0">
                      cycling
                    </p>
                  </div>
                  <div
                    className="d-lg-none transparent-bg align-items-center position-absolute top-0 end-0 mx-4 my-4 border border-white rounded-circle px-2 py-1 mobile-share-only"
                    style={{ fontWeight: "600" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-share-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                    </svg>
                  </div>
                  <Row>
                    <div className="col-auto position-absolute bottom-0 start-0 ms-2 mb-3">
                      <h2 className="text-white text-start bebas-neue-font mb-0">
                        Hyper cycling
                      </h2>
                      <p className="text-white text-start fs-6 plus-jakarta-sans-font mb-0">
                        <span className="text-default-color">254 people</span>{" "}
                        has already started this event
                      </p>
                    </div>
                    <div className="col-auto position-absolute bottom-0 end-0 me-lg-2 mb-3">
                      <Button
                        type="button"
                        className="rounded-pill btn-default fs-5 fw-bold border-white"
                      >
                        Join Now
                      </Button>
                    </div>
                  </Row>
                  <div className="geeks rounded-4">
                    <img
                      src="media/assets/events/hyper-cycling.jpg"
                      className="img-fluid rounded-4"
                      alt="cycle"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} className="col-12 mb-4">
                <div className="image-container">
                  <div className="overlay rounded-4"></div>
                  <div
                    className="d-flex transparent-bg align-items-center position-absolute top-0 top-right mx-4 my-4 border border-white rounded-pill px-3 py-1 gap-2 mobile-cycle-only"
                    style={{ fontWeight: "600" }}
                  >
                    <img
                      src="/media/assets/svg/directions-bike.svg"
                      height={20}
                      loading="lazy"
                    />
                    <p className="text plus-jakarta-sans-font fs-6 text-uppercase text-dark my-0">
                      cycling
                    </p>
                  </div>
                  <div
                    className="d-lg-none transparent-bg align-items-center position-absolute top-0 end-0 mx-4 my-4 border border-white rounded-circle px-2 py-1 mobile-share-only"
                    style={{ fontWeight: "600" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-share-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                    </svg>
                  </div>
                  <Row>
                    <div className="col-auto position-absolute bottom-0 start-0 ms-2 mb-3">
                      <h2 className="text-white text-start bebas-neue-font mb-0">
                        Hyper cycling
                      </h2>
                      <p className="text-white text-start fs-6 plus-jakarta-sans-font mb-0">
                        <span className="text-default-color">254 people</span>{" "}
                        has already started this event
                      </p>
                    </div>
                    <div className="col-auto position-absolute bottom-0 end-0 me-lg-2 mb-3">
                      <Button
                        type="button"
                        className="rounded-pill btn-default fs-5 fw-bold border-white"
                      >
                        Join Now
                      </Button>
                    </div>
                  </Row>
                  <div className="geeks rounded-4">
                    <img
                      src="media/assets/events/hyper-cycling.jpg"
                      className="img-fluid rounded-4"
                      alt="hyper-cycle"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} className="col-12 mb-4">
                <div className="image-container">
                  <div className="overlay rounded-4"></div>
                  <div
                    className="d-flex transparent-bg align-items-center position-absolute top-0 top-right mx-4 my-4 border border-white rounded-pill px-3 py-1 gap-2 mobile-cycle-only"
                    style={{ fontWeight: "600" }}
                  >
                    <img
                      src="/media/assets/svg/directions-bike.svg"
                      height={20}
                      className="img-fluid"
                      alt="bike-ride"
                      loading="lazy"
                    />
                    <p className="text plus-jakarta-sans-font fs-6 text-uppercase text-dark my-0">
                      cycling
                    </p>
                  </div>
                  <div
                    className="d-lg-none transparent-bg align-items-center position-absolute top-0 end-0 mx-4 my-4 border border-white rounded-circle px-2 py-1 mobile-share-only"
                    style={{ fontWeight: "600" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-share-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                    </svg>
                  </div>
                  <Row>
                    <div className="col-auto position-absolute bottom-0 start-0 ms-2 mb-3">
                      <h2 className="text-white text-start bebas-neue-font mb-0">
                        Hyper cycling
                      </h2>
                      <p className="text-white text-start fs-6 plus-jakarta-sans-font mb-0">
                        <span className="text-default-color">254 people</span>{" "}
                        has already started this event
                      </p>
                    </div>
                    <div className="col-auto position-absolute bottom-0 end-0 me-lg-2 mb-3">
                      <Button
                        type="button"
                        className="rounded-pill btn-default fs-5 fw-bold border-white"
                      >
                        Join Now
                      </Button>
                    </div>
                  </Row>
                  <div className="geeks rounded-4">
                    <img
                      src="media/assets/events/hyper-cycling.jpg"
                      className="img-fluid rounded-4"
                      alt="hypercycle"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section-background">
          <Container>
            <Row>
              <Col className="col-12">
                <div className="d-flex justify-content-center flex-column flex-wrap pt-5">
                  <h1 className="bebas-neue-font text-center">
                    DOWNLOAD THE Move APP <br />
                    AND JOIN NOW!{" "}
                  </h1>
                  <h4 className="plus-jakarta-sans-font text-secondary">
                    Let’s Get Moving!
                  </h4>
                </div>
              </Col>
              <Col className="col-12">
                <div className="d-flex flex-row flex-wrap gap-3 justify-content-center align-items-center pt-4 pb-5">
                  <div>
                    <img
                      src="\media\assets\events\google-play.png"
                      alt="google-play"
                      className="img-fluid d-block"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src="\media\assets\events\app-store.png"
                      alt="app-store"
                      className="img-fluid d-block"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default EmailPage;
