import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { GetKilometers, getEventsessionTime } from "../../../services/utility";
import { cycling, running, steps, walking } from "../../../shared/constants";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const ActivityCard = (props: any) => {
    const { history, page, isLoading } = props

    const [isTodaydata, setIsTodayData] = useState<boolean>(false);
    useEffect(() => {
        if (history?.date) {
            const todayActivityDate = moment(new Date()).format('YYYY-MM-DD');
            const activityDate = moment(new Date(history?.date)).format('YYYY-MM-DD');
            if (todayActivityDate == activityDate) {
                setIsTodayData(true);
            }
            else {
                setIsTodayData(false)
            }

        }
    }, [history?.date])

    return (
        <>
            <div className="px-3">
                <Row className="bg-F3F3F3 p-4 rounded-4">
                    <Col sm={6} className="col-md-12">
                        <div className="row card-title mb-0 h5">
                            <div className="col-md-6">
                                <span className="text-uppercase bebas-neue-font font-22">
                                    {(isTodaydata === true || page == true) ? (<>Today</>) : (<>{history?.date && moment(history?.date).format('DD , MMM')}</>)}
                                </span>
                            </div>
                            <div className="col-md-6 text-end">
                                {
                                    history?.totalSteps > 0 ? (
                                        <div className="fw-500 font-16">
                                            <span className="px-1">
                                                <img
                                                    src={steps}
                                                    alt="steps"
                                                    height={15}
                                                    className="img-fluid"
                                                    loading="lazy"
                                                />

                                            </span>
                                            <span className="fw-500 font-16">
                                                {isLoading ? (<Skeleton height={20} width={80} direction={"ltr"} />) : (`${history?.totalSteps} steps`)}
                                            </span>
                                        </div>

                                    ) : (
                                        <div className="fw-500 font-16">
                                            <span className="px-1">
                                                <img
                                                    src={steps}
                                                    alt="steps"
                                                    height={15}
                                                    className="img-fluid"
                                                    loading="lazy"
                                                />

                                            </span>
                                            <span className="fw-500 font-16">
                                                {isLoading ? (<Skeleton height={20} width={80} direction={"ltr"} />) : (`${0} steps`)}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <hr className="my-3 text-5F6368" />
                    </Col>
                    <Col sm={6} className="col-md-12">
                        <>
                            {
                                isLoading ?
                                    (
                                        <Skeleton direction={"ltr"} count={5} />
                                    ) : (
                                        history?.activity?.length > 0 ? (
                                            history?.activity?.map((e: any) => (
                                                <Row className="my-2">
                                                    <Col sm={6} className="col-md-7">
                                                        <Row>
                                                            <Col className="col-md-2">
                                                                <img
                                                                    src={
                                                                        e?.eventTypeName === "Walking"
                                                                            ? walking
                                                                            : e?.eventTypeName === "Cycling"
                                                                                ? cycling
                                                                                : e?.eventTypeName === "Running" && running
                                                                    }
                                                                    alt="eventType"
                                                                    width={50}
                                                                    className="img-fluid"
                                                                    loading="lazy"
                                                                />
                                                            </Col>
                                                            <Col className="col-md-5 d-flex justify-content-start align-items-center">
                                                                <span className="fw-500 font-18 text-color-black">
                                                                    {getEventsessionTime(e?.startTime, e?.eventTypeName)}
                                                                </span>
                                                            </Col>
                                                            <Col className="col-md-5 d-md-block d-none d-md-flex justify-content-center align-items-center">
                                                                <span className="fw-500 font-18 text-capitalize text-color-black">
                                                                    {e?.eventTypeName}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={6} className="col-md-5">
                                                        <Row>
                                                            <Col className="col-md-4 d-flex justify-content-center text-center">
                                                                <div className="d-flex flex-column">
                                                                    <div className="fw-400 text-5F6368 font-14">Distance</div>
                                                                    <div className="bebas-neue-font font-18">{`${GetKilometers(e?.distance)} KM`}</div>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-md-4 d-flex  justify-content-center border-start border-end text-center">
                                                                <div className="d-flex flex-column">
                                                                    <div className="fw-400  text-5F6368 font-14">Pace</div>
                                                                    <div className="bebas-neue-font font-18">{`${e?.speed} /KM`}</div>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-md-4 d-flex  justify-content-center text-center">
                                                                <div className="d-flex flex-column">
                                                                    <div className="fw-400 text-5F6368 font-14">Time</div>
                                                                    <div className="bebas-neue-font font-18">{e?.timeDuration}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                // ))
                                            ))
                                        ) : (<>
                                            <div className="empty-state__content p-2">
                                                <div className="empty-profile-state__icon p-1">
                                                    <img
                                                        src="/media/assets/events/no-found-gray.png"
                                                        alt="no-data"
                                                        className="img-fluid"
                                                        loading="lazy"
                                                    />
                                                </div>
                                                <div className="p-4 fs-6 fw-500">
                                                    No record found.
                                                </div>
                                            </div>
                                        </>)
                                    )
                            }

                        </>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ActivityCard;
