import "./App.css";
import { useLocation } from "react-router-dom";
import Header from "./Common/Header/Header";
import Footer from "./Common/Footer/Footer";
import { useEffect, useState } from "react";
import useJwt from "./hooks/useJwt";
import { AppRoutes } from "./routing/AppRoutes";
import { AuthInit, AuthProvider, useAuth } from "./module/auth/core/Auth";
import React from "react";
import { ScrollToTop } from "./services/utility";
import { WEB_ENDPOINT } from "./shared/apiConfig";

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isAuthRoute =
    location.pathname === "/auth" || location.pathname.startsWith("/error");
  const { isTokenValid } = useJwt();
  const { logout } = useAuth();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2700);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!isTokenValid()) {
      logout();
    }
  }, [isTokenValid, logout]);

  return (
    <div className="App">
      {loading ? (
        <div className="loader-container">
          <img src={`${WEB_ENDPOINT}/Move-Favicon.png`} width={50} height={50} alt="MOVE" className="img-fluid" />
        </div>
      ) : (
        <>
          <AuthProvider>
            {!isAuthRoute && <Header />}
            <AuthInit />
            <ScrollToTop />
            <AppRoutes />
            {!isAuthRoute && <Footer />}
          </AuthProvider>
        </>
      )}
    </div>
  );
}

export default App;
