import React from "react";
import { Row } from "react-bootstrap";

const BlankPage = () => {
  return (
    <>
      <Row>
        <div className="col-12">
          <div className="empty-state my-2">
            <div className="empty-state__content">
              <div className="empty-state__icon">
                <img
                  src="/media/assets/events/no-found-gray.png"
                  alt="no_data"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="empty-state__message fs-3 fw-500">
                No events has been found.
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default BlankPage;
