import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Dropdown } from "react-bootstrap";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import CheckboxItem from "../../Common/Dropdown/CheckboxItem";
import ParticipatedEventCards from "../../Common/Card/ParticipatedEventCards";
import { Icon } from "@iconify/react";
import RadioGroupInput from "../../Common/Dropdown/RadioGroupInput";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { Getallparticipatedevents } from "../../services/Web.type";
import BlankPage from "../../Common/BlankData/BlankPage";
import Skeleton from "react-loading-skeleton";
import { WebFilterModel } from "../../module/auth/core/_models";
import { number } from "yup";


interface EventType {
  id: number;
  name: string;
}

const ParticipatedEvent = (props: any) => {

  const [activeButton, setActiveButton] = useState<string | null>("All");
  const [isActiveFilter, setIsActiveFilter] = useState<string | null>("All");
  const [isStarted, setisStarted] = useState<boolean | null>(null);
  const [selectedEventTypes, setSelectedEventTypes] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const webJournalResponseModel: WebFilterModel = {
    search: "",
    eventTypeID: selectedEventTypes,
    page: page,
    limit: limit,
    isGetAll: true,
    isStarted: isStarted
  }
  const [filter, SetFilter] = useState<WebFilterModel>(webJournalResponseModel)
  const eventTypes: EventType[] = [
    { id: 3, name: "Running" },
    { id: 1, name: "Cycling" },
    { id: 2, name: "Walking" }
  ];


  const getallparticipatedevents = usePostQuery(
    "getallparticipatedevents",
    Getallparticipatedevents,
    {}
  );

  useEffect(() => {
    getallparticipatedevents.mutate(filter);
  }, [filter]);

  useEffect(() => {
    if (getallparticipatedevents.isSuccess) {
      const events = getallparticipatedevents.data?.result || [];
      if (events.length < 6) {
        setIsLoadingMore(false)
        setFilteredEvents((prevEvents) => [...prevEvents, ...events]);
      }
      else {
        setFilteredEvents((prevEvents) => [...prevEvents, ...events]);
        setIsLoadingMore(true)
      }

    }
  }, [getallparticipatedevents.isSuccess]);

  const FilterByEventType = (buttonId: string | null) => {
    setActiveButton(buttonId);
    setIsActiveFilter(buttonId);
    setFilteredEvents([]);
    const eventType = eventTypes.find((type) => type.name === buttonId);
    const newSelectedEventTypes = eventType ? [eventType.id] : [];
    setSelectedEventTypes(newSelectedEventTypes);
    setPage(1);
    if (buttonId === "Active") {
      SetFilter((prevFilter) => ({
        ...prevFilter,
        page: 1,
        isStarted: true
      }));
    }
    else {
      SetFilter((prevFilter) => ({
        ...prevFilter,
        page: 1,
        eventTypeID: newSelectedEventTypes,
        isStarted: null
      }));
    }
  };

  const ActiveEventFilter = (buttonId: string | null) => {
    setIsActiveFilter(buttonId);
    setFilteredEvents([]);
    setPage(1);

    if (buttonId === "Active") {
      SetFilter((prevFilter) => ({
        ...prevFilter,
        page: 1,
        isStarted: true
      }));
    }
    else {
      SetFilter((prevFilter) => ({
        ...prevFilter,
        page: 1,
        isStarted: null
      }));
    }
  };

  const handleLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    setIsLoadingMore(true);
    SetFilter((prevFilter) => ({
      ...prevFilter,
      page: newPage,
    }));
  };

  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-1 p-0">
                  <PageNavigation currentPage={"My Events"} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="pb-md-1 p-0">
              <div className="row">
                <div className="col-md-10">
                  <button
                    type="button"
                    className={`btn rounded-pill me-2  plus-jakarta-sans-font fw-bolder text-uppercase ${activeButton === "All" ? "bg-default text-black" : "text-5F6368 bg-F3F3F3"}`}
                    onClick={() => FilterByEventType("All")}
                  >

                    All

                  </button>
                  {eventTypes.map((eventType) => (
                    <button
                      key={eventType.id}
                      type="button"
                      className={`plus-jakarta-sans-font fw-bolder btn rounded-pill me-2 my-1 text-uppercase ${activeButton === eventType.name ? ("bg-default text-black") : ("bg-F3F3F3 text-5F6368")}`}
                      onClick={() => FilterByEventType(eventType.name)}
                    >
                      {eventType?.name === "Running" ?
                        (<Icon icon="ic:baseline-directions-run" className={`${activeButton === eventType.name ? "text-black" : "text-5F6368"}`} fontSize={24} />) : (eventType?.name === "Walking" ? (<Icon icon="material-symbols:directions-walk" className={`${activeButton === eventType.name ? "text-black" : "text-5F6368"}`} fontSize={24} />) : (eventType?.name === "Cycling" ? <Icon icon="bx:cycling" className={`${activeButton === eventType.name ? "text-black" : "text-5F6368"}`} fontSize={24} /> : <></>))}
                      <span className="d-none d-md-inline">{eventType.name}</span>
                    </button>
                  ))}
                </div>
                <div className="col-md-2 d-flex justify-content-end">
                  <Dropdown className="pe-lg-5 pe-md-2" autoClose="outside">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="rounded-pill bg-F3F3F3 custom-toggle border-0"
                    >
                      <Icon icon="material-symbols:instant-mix" className="text-5F6368" fontSize={24} />
                      <span className="text-5F6368 fw-500 ps-2 text-uppercase font-16 d-none d-md-inline">Filter</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <RadioGroupInput
                        className="fw-400 font-18 text-black"
                        id="All"
                        isChecked={isActiveFilter === "All"}
                        checkHandler={() => ActiveEventFilter("All")}
                        label="All"
                      />
                      <RadioGroupInput
                        className="fw-400 font-18 text-black"
                        id="Active"
                        isChecked={isActiveFilter === "Active"}
                        checkHandler={() => ActiveEventFilter("Active")}
                        label="Active Events"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Container>
            <div className="mt-3 pb-md-1 p-0">
              <div className="row">
                {filteredEvents?.map((item: any) => (
                  <ParticipatedEventCards key={item.id} item={item} />
                ))}
                {<>
                  {(getallparticipatedevents?.isLoading) && Array.from({ length: 6 }).map((_, index) => (
                    <div className="col-md-4 col-xl-4 mb-4 move-up">
                      <Skeleton className="rounded-4" height={367} />
                    </div>
                  ))}
                </>}
                {filteredEvents.length === 0 && <BlankPage />}
              </div>
              {isLoadingMore && filteredEvents.length != 0 &&
                (
                  <Row className="justify-content-center">
                    <div className="col-12">
                      <div className="button-container">
                        <hr
                          className="hr-text"
                          data-content="Load More"
                          onClick={handleLoadMore}
                        />
                      </div>
                    </div>
                  </Row>
                )}
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default ParticipatedEvent;
