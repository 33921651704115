import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const PageNavigation = (props: any) => {
  const { currentPage } = props;
  return (
    <>
      {/* <div className="container "> */}
      <div className="row">
        <div className="col-12 ">
          <div className="section-header-padding">
            <div className="row">
              <div className="col-md-12 d-none breadcrumb-navigation d-sm-flex justify-content-end pt-sm-2">
                <span className="">
                  <Link className="fw-400 breadcrum-h font-20 b-crum" to="/">
                    Home
                  </Link>
                  <span className="px-md-3">
                    <Icon icon="fa6-solid:greater-than" style={{ color: "#fecc09" }} />
                  </span>
                  <span className="text-capitalize fw-400 font-20 b-crum">{currentPage}</span>
                </span>
              </div>
              <div className="col-md-12 navigation-container-page text-center mt-3">
                <span className="text-center fw-600">
                  <span className="text-dark text-uppercase font-40">
                    {currentPage == "FAQ'S" ? ("Frequently asked questions") : (currentPage)}
                  </span>
                </span>
                {(currentPage == "About Us" || currentPage == "Contact Us" || currentPage == "FAQ'S") && <hr />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNavigation;
