import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import { MobileSlider } from "../../Common/MobileSlider/MobileSlider";
import BlankPage from "../../Common/BlankData/BlankPage";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { GetPopularEvents, GetUpcomingEvents } from "../../services/Web.type";
import { WebPopularEventModel, WebUpcomingEventModel } from "../../module/auth/core/_models";
import HomePageEventSlider from "../../Common/Card/HomePageEventSlider";
import CardSlide from "../../Common/Card/CardSlide";
import { clsx } from "clsx";

const Home: React.FC = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      behaviour: "smooth",
    });
  }, []);

  const getPopularEventsQuery = usePostQuery("GetPopularEvents", GetPopularEvents);
  const getUpcomingEventQuery = usePostQuery("getUpcomingEvents", GetUpcomingEvents);
  const [popularEvents, setPopularEventList] = useState<WebPopularEventModel[]>([]);
  const [upcomingEvents, setupcomingEventsList] = useState<WebUpcomingEventModel[]>([]);

  useEffect(() => {
    Promise.resolve(
      getPopularEventsQuery.mutate({})
    )
  }, [])

  useEffect(() => {
    getUpcomingEventQuery.mutate({
      search: "",
      eventTypeID: [],
      isGetAll: false,
    });
  }, []);

  useEffect(() => {
    if (getPopularEventsQuery?.isSuccess) {
      setPopularEventList(getPopularEventsQuery?.data?.result);
    }
  }, [getPopularEventsQuery?.isSuccess])
  useEffect(() => {
    if (getUpcomingEventQuery?.isSuccess) {
      setupcomingEventsList(getUpcomingEventQuery?.data?.result);
    }
  }, [getUpcomingEventQuery?.isSuccess])


  return (
    <>
      <main>
        <section id="header_slide" className="header_slide">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6} className="p-4 fade-left">
                <div className="text-start">
                  <span className="bebas-neue-font font-70">
                    GET MOVING WITH
                    <span className="default-text-color"> Move!</span>
                  </span>
                  <p className="mb-4 fw-500 font-18 home-header-section text-5F6368 lh-card">
                    Download the Move app, your one-stop fitness companion for
                    cycling, running, and walking events. Get all the
                    information and event details you need at your fingertips.
                    With a user-friendly interface, you can easily find, join,
                    and participate in various fitness events. Take your fitness
                    journey to the next level with Move!
                  </p>
                  {/* <Row>
                    <Col className="col-12"> */}
                  <div className="row pt-4 pb-5">
                    <div className="col-md-6 col-lg-4 col-6">
                      <Link
                        to="https://play.google.com/store/apps/details?id=com.iamonthemove.move"
                        target="_blank"
                        className="text-decoration-none "
                      >
                        <div className="text-white">
                          <img src="\media\assets\svg\google-play.svg" alt="googleplay" className="img-fluid" loading="lazy" />
                        </div>

                      </Link>
                    </div>
                    <div className="col-md-6 col-lg-4 col-6">

                      <Link to="https://apps.apple.com/in/app/move-i-am-on-the-move/id6504929310" target="_blank">
                        <img
                          src="\media\assets\svg\get-apple-app.svg"
                          alt="apple"
                          className="img-fluid"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                  </div>
                  {/* </Col>
                  </Row> */}
                </div>
              </Col>
              <Col lg={6} md={6} className="p-4 fade-right">
                <div className="d-flex align-items-center justify-content-end">
                  <img
                    src="media\assets\logo\group-2.png"
                    className="img-fluid img"
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {popularEvents && popularEvents?.length > 0 &&
          <section id="popularevents">
            <div className="container-fluid">
              <div className="row px-md-5 py-md-3 p-0 ">
                <span className="text-center text-dark text-uppercase fw-500 font-54">Popular events</span>
              </div>
              <Row>
                {popularEvents?.length > 0 ? (
                  <HomePageEventSlider eventList={popularEvents} pageName={'home'} />
                ) : (
                  <BlankPage />
                )}
              </Row>
            </div>
          </section>}
        <section id="about-events">
          <Container className="about-events-container">
            <div className="about-events-area rounded-4">
              <Row className="mx-0 justify-content-center">
                <Col lg={12} className="px-0">
                  <div className="mb-3 text-center">
                    <span className="text-dark fw-500 font-54">
                      WHAT IS MOVE?
                    </span>
                  </div>
                </Col>
                <Row>
                  <Col lg={6} md={12}>
                    <div className="text-center">
                      <p className="fw-500  text-5F6368 font-18 px-3 home-header-section lh-card">
                        Your ideal companion for cycling, running, and walking
                        events. This app is designed to empower your fitness
                        journey. From tracking your activities to joining exciting
                        events and challenges, Move has you covered. Connect with
                        fellow enthusiasts, monitor your progress, and reach new
                        fitness heights. Download the app now and get ready to
                        move, one step at a time.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="text-center">
                      <p className="fw-500 text-5F6368 font-18 px-3 home-header-section lh-card">
                        Meet the Move app, your ultimate hub for cycling, running,
                        and walking events. Here, you'll discover a variety of
                        events and all the details you need to join seamlessly.
                        Plus, with our tracking feature, you can monitor your
                        progress and participate in events like never before. Join
                        us on the Move app and let's make every step count!
                      </p>
                    </div>
                  </Col>
                </Row>
              </Row>
            </div>
          </Container>
        </section>
        <section id="about-event-details">
          <Container className="about-event-details-container">
            <Row className="align-items-center about-event-details-area">
              <Col lg={5} md={6} className="order-2 order-lg-1 pt-5">
                <div
                  className="aa-about-event-details-left text-start"
                  data-aos="fade-right"
                >
                  <img
                    src="\media\assets\events\mobile-screen.png"
                    alt="mobile-screen"
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
              </Col>
              <Col lg={7} md={6} className="order-lg-2 p-4">
                <div className="text-start d-flex flex-column" data-aos="fade-left">
                  <div className="text-dark fw-500 font-54 m-0" style={{ lineHeight: '3rem' }}>
                    EVENT DETAILS
                  </div>
                  <div className="mb-2">
                    <span className="text-lowercase fw-500 font-26">
                      Join Now and Be a Part of the Action
                    </span>
                  </div>

                  <div className="fw-400 font-18 home-header-section lh-card text-5F6368">
                    Welcome to the Event Detail Page, your comprehensive source
                    for all the event details. Here, you can find information
                    about every event, including date, time, and unique
                    features. Discover events from all categories, and choose
                    the one that piques your interest. Your next adventure is
                    just a click away.our app meticulously stores and showcases
                    the count of all events, ensuring you stay informed and
                    engaged in every adventure
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {upcomingEvents && upcomingEvents?.length &&
          <section id="upcomingEvents-home">
            <div className={clsx(upcomingEvents?.length > 3 ? 'container-fluid py-3' : 'container py-3')}>
              <div className="row px-md-5 py-md-3 p-0 ">
                <span className="text-center text-dark text-uppercase fw-500 font-54">Upcoming events</span>
              </div>
              <Row>
                {upcomingEvents?.length > 0 ? (
                  (upcomingEvents?.length > 3) ? (
                    <HomePageEventSlider eventList={upcomingEvents} pageName={'upcomingEvent'} />
                  ) : (
                    <div className="col-12">
                      <CardSlide getUpcomingEventQuery={getUpcomingEventQuery} pageName={'upcomingEvent'} isdetailpage={true} />
                    </div>
                  )
                ) : (
                  <BlankPage />
                )}
              </Row>
            </div>
          </section>}
        <section
          id="fitness_journey"
          className="fitness_journey_bg position-relative"
        >
          <Container className="py-5">
            <Row className="my-5">
              <Col md={6} className="d-flex align-items-center p-4">
                <div className="text-start" data-aos="fade-right">
                  <h5 className="fw-600 text-black font-20">Get Organized!</h5>
                  <div className="fw-500 font-54 w-75 lh-sm">
                    SIMPLIFY YOUR FITNESS JOURNEY!
                  </div>
                  <p className="fw-400 font-20 home-header-section lh-25 text-5F6368">
                    You can easily view your complete activity history on a
                    single screen. From your morning, evening, to nighttime
                    activities, and even the steps taken during various events
                    it's all there at your fingertips. Stay in the know about
                    your fitness progress throughout the day
                  </p>
                </div>
              </Col>
              <Col md={6} lg={6} className="col-12 mb-4 mb-lg-0">
                <div
                  className="custom-block rounded-3"
                  data-aos="fade-left"
                >
                  <img src="media\assets\svg\Group-50.svg" alt="activity-journal" className="img-fluid" loading="lazy" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="activity_tracker" className="activity-tracker py-5">
          <Container>
            <Row className="align-items-center">
              <Col
                lg={6}
                md={6}
                className="d-flex align-items-center order-2 order-lg-1"
              >
                <div className="activity-tracker-text" data-aos="fade-right">
                  <img
                    src="media\assets\svg\mockup-2.svg"
                    className="img-fluid d-block"
                    alt="mockup-2"
                    loading="lazy"
                  />
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                className="d-flex align-items-center order-1 order-lg-2 p-4"
              >
                <div className="text-start" data-aos="fade-left">
                  <span className="text-dark fw-500 font-54">
                    ACTIVITY TRACKER
                  </span>
                  <p className="fw-400 font-18 home-header-section lh-card text-5F6368">
                    Meet our Activity Tracker – your ultimate companion for
                    cycling, running, and walking events. This smart tracker
                    diligently counts every step, kilometer, and minute,
                    providing a detailed record of your performance. From steps
                    taken to distance covered, and time spent, it stores all
                    your data for a comprehensive overview of your fitness
                    journey. Stay motivated and track your progress
                    effortlessly.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="join_event" className="join-event">
          <Container className="join-event-container pt-5 px-4">
            <Row className="join-event-top"> {/*justify-content-center align-items-center*/}
              <Col md={5}>
                <div className="text-start" data-aos="fade-right">
                  <span className="text-dark fw-500 font-54">
                    JOINING EVENT
                  </span>
                  <p className="text-start fw-400  font-18 home-header-section lh-card text-5F6368">
                    Unlock the world of events through our app. Joining an event
                    is as simple as a few taps on your screen. Pay the event
                    fees online, and your participation is confirmed. With our
                    app, event registration has never been this easy. Please
                    note that joining fees are compulsory for participation.
                    Don't miss out on exciting events – it's just a few clicks
                    away!
                  </p>
                </div>
              </Col>
              <Col md={7} className="order-md-2">
                <div
                  className="text-center"
                  data-aos="fade-left"
                >
                  <img
                    src="media\assets\events\hand-mobile.png"
                    alt="hand mobile"
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="user_interface" className="user-interface">
          <Container className="pt-5">
            <Row className="justify-content-center">
              <Col lg={12} md={12}>
                <h5 className="fw-600 text-center text-5F6368">
                  User Interface
                </h5>
              </Col>
              <Col lg={5} className="col-10">
                <h1 className="fw-500 text-center font-42 text-uppercase">
                  Take a visual tour of our intuitive interface
                </h1>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col>
                <MobileSlider />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="download_app" className="section-background">
          <DownloadSection />
        </section>
      </main>
    </>
  );
};

export default Home;
