import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import LazyImage from "react-lazy-blur-image";
import { useNavigate } from "react-router-dom";
import { default_imageCycling, default_imageRunning, default_imageWalking } from "../../shared/constants";
const ParticipatedEventCards = (props: any) => {
    const { item } = props;
    const cycling = "/media/assets/svg/directions-bike.svg";
    const walking = "/media/assets/svg/directions-walk.svg";
    const running = "/media/assets/svg/directions-run.svg";

    const navigate = useNavigate();
    const additionalData = {
        page: true
    }
    const handleButtonClick = (value: any) => {
        // window.location.href = `${item.pageURL}`;
        navigate(`/eventdetail/${item.pageURL}`, { state: additionalData?.page });
    };

    return (
        <>
            <div className="col-md-6 col-lg-4 mb-4 move-up cursor-pointer">
                <div className="card position-relative rounded-4 border-0 bg-light" onClick={handleButtonClick} >
                    <LazyImage
                        uri={
                            item.webBannerImage
                                ? (item.webBannerImage)
                                : (item?.eventTypeID === 1
                                    ? default_imageCycling
                                    : item?.eventTypeID === 2
                                        ? default_imageWalking
                                        : item?.eventTypeID === 3
                                            ? default_imageRunning
                                            : "")
                        }
                        placeholder={
                            item.webBannerImage
                                ? (item.webBannerImage)
                                : (item?.eventTypeID === 1
                                    ? default_imageCycling
                                    : item?.eventTypeID === 2
                                        ? default_imageWalking
                                        : item?.eventTypeID === 3
                                            ? default_imageRunning
                                            : "")
                        }
                        render={(src: any, style: any) => (
                            <img
                                src={src}
                                className="card-img-top rounded-top-4 img-fluid"
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                    e.currentTarget.src = item?.eventTypeID === 1
                                        ? default_imageCycling
                                        : item?.eventTypeID === 2
                                            ? default_imageWalking
                                            : item?.eventTypeID === 3
                                                ? default_imageRunning
                                                : ""
                                }}
                                alt="BannerImage"
                                loading="lazy"
                            />
                        )}
                    />
                    <div className="card-body bg-F3F3F3 rounded-bottom-4">
                        <div className="d-flex gap-2 position-absolute top-0 my-3 mx-3 end-0">
                            <div className=" d-flex tab-view-share-btn transparent-bg align-items-center rounded-pill px-3 py-md-0 py-1 gap-2 mobile-cycle-only font-12">
                                <img
                                    src={
                                        item?.eventTypeID === 1
                                            ? cycling
                                            : item?.eventTypeID === 2
                                                ? walking
                                                : item?.eventTypeID === 3
                                                    ? running
                                                    : ""
                                    }
                                    alt="event-type"
                                    height={18}
                                    loading="lazy"
                                />

                                <p className="text text-uppercase my-1 fw-600 text-black d-md-block d-none font-12">
                                    {item.eventTypeName}
                                </p>
                            </div>
                        </div>
                        <div className="card-text mb-2">
                            <span className="fw-500 text-black text-capitalize d-md-inline-block text-truncate font-16">
                                {`${item?.eventName?.substring(0, 30)}${item?.eventName?.length > 30 ? '...' : ''}`}
                            </span>
                        </div>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <div>
                                    <Icon icon="ic:sharp-leaderboard" style={{ color: "#767676" }} fontSize={22} />
                                    <span className=" fs-6 plus-jakarta-sans-font">
                                        Your Rank
                                    </span>
                                </div>
                                <div>
                                    <span className="fs-4 bebas-neue-font">
                                        {item?.ranking == 0 ? ('-') : item?.ranking}
                                    </span>
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className="row">
                                    {/* {(item?.eventTypeID != 1) && (
                                        <div className="col-lg-6">
                                            <Icon icon="material-symbols:steps" className="me-lg-1 text-5F6368" fontSize={17} />
                                            <span className="font-16 fw-500 font-lg-16 text-5F6368">
                                                Total Steps
                                            </span>
                                        </div>
                                    )} */}
                                    {/* {(item?.eventTypeID == 1) && ( */}
                                    <div className="col-lg-6">
                                        <Icon icon="material-symbols:steps" className="me-lg-1 text-5F6368" fontSize={17} />
                                        <span className="font-16 fw-500 font-lg-16 text-5F6368">
                                            Total Distance
                                        </span>
                                    </div>
                                    {/* )} */}

                                </div>
                                <div className="row">
                                    {/* {(item?.eventTypeID != 1) && (
                                        <div className="col-lg-6">
                                            <span className="bebas-neue-font font-26">
                                                {(item?.totalSteps == 0 || item?.totalSteps == null) ? ('0') : item?.totalSteps}
                                            </span>
                                        </div>
                                    )} */}
                                    {/* {(item?.eventTypeID == 1) && ( */}
                                    <div className="col-lg-6">
                                        <span className="font-26 bebas-neue-font">
                                            {(item?.totalDistance == 0 || item?.totalDistance == null) ? ('0') : Math.floor((item?.totalDistance * 0.001))} {'KM'}
                                        </span>
                                    </div>
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-absolute bottom-0 end-0">
                        {item?.isStarted && <button type="button" className="px-3 py-2 border-0 btn-default participatedactivebutton fw-600 font-12 text-uppercase">Active</button>}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ParticipatedEventCards;