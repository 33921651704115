import { FC } from 'react'
import { Link } from 'react-router-dom'


const Error500: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>System Error</h1>
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        Something went wrong! Please try again later.
      </div>
      <div className='mb-11'>
        <img
          src={'../media/assets/error/500-error.png'}
          className='mw-100 mh-300px theme-light-show'
          alt='error'
          loading="lazy"
        />
        <img
          src={'../media/assets/error/500-dark.png'}
          className='mw-100 mh-300px theme-dark-show'
          alt='error'
          loading="lazy"
        />
      </div>
      <div className='mb-0'>
        <Link to='/' className='btn btn-sm btn-primary'>
          Return Home
        </Link>
      </div>
    </>
  )
}

export { Error500 }
