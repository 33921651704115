const AboutUsCard = (props: any) => {
    const { headerTitle, content, page } = props
    return (
        <>
            <div className="col-md-4 mb-xl-auto mb-2 col-12">
                <div className="card border-0 about-card">
                    <div className={`${page == "about-us" ? "card-header fs-4 fw-600 border-0  border-bottom-0 pt-1 pb-1 text-center d-flex flex-column align-items-center about-event-details-us" : "card-header fs-4 fw-600 border-0 bg-white border-bottom-0 pt-1 pb-1 text-center d-flex flex-column align-items-center"}`}>
                        <span>
                            {
                                (page == "about-us") ? (
                                    <img
                                        src={
                                            headerTitle == "Economical Rates" ? "/media/assets/about-us/rupees_icon.png" : headerTitle == "Global Community" ? "/media/assets/about-us/glob_icon.png" : headerTitle == "Easy Participation" ? "/media/assets/about-us/vote_icon.png" : headerTitle == "Exciting Rewards" ? "/media/assets/about-us/certified_icon.png" : headerTitle == "Seamless Experience" ? "/media/assets/about-us/hand_icon.png" : ""
                                        }
                                        width={37}
                                        alt="icon"
                                        loading="lazy"
                                    />) : (
                                    <img
                                        src={
                                            headerTitle == "Comprehensive Tracking" ? "/media/assets/about-us/tracking.svg" : headerTitle == "Health Improvement" ? "/media/assets/about-us/health.svg" : headerTitle == "Motivation and Support" ? "/media/assets/about-us/Motive.svg" : headerTitle == "Exclusive Merchandise" ? "/media/assets/about-us/merch.svg" : headerTitle == "Convenient Access" ? "/media/assets/about-us/access.svg" : ""
                                        }
                                        width={37}
                                        height={37}
                                        alt="icon"
                                        loading="lazy"
                                    />
                                )
                            }


                        </span>
                        <span className="text-justify text-uppercase">
                            {headerTitle}
                        </span>
                    </div>
                    <div className={`${page == "about-us" ? "card-body pt-1 about-event-details-us" : "card-body pt-1"}`}>
                        <p className="card-text font-18 font-5E5E5E fw-400 lh-card mh-120 text-justify">{content}</p>
                    </div>
                </div>
            </div>
        </>
    )

}
export default AboutUsCard;
