/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  useGetQuery,
  usePostQuery,
  usePutQuery,
} from "../../hooks/reactQueryHelper";
import {
  EditUserProfile,
  GenerateOtp,
  GetAllStatesById,
  GetUserProfile,
  ValidateOtp,
} from "../../services/Web.type";
import { EditUserProfileModel } from "../../services/UserProfile/userProfile.type";
import ReactDatePicker from "react-datepicker";
import toastify from "../../helper/toastify";
import { useNavigate } from "react-router-dom";
import ImageCrop from "../../Common/ImageCropper";
import { USER_DEFAULT_IMAGE_PATH } from "../../shared/constants";
import ImageUpload from "../../Common/ImageCropper";
import { toAbsoluteUrl } from "../../helper";
import { removeAuth, setAuth } from "../../module/auth/core/AuthHelper";
import { useAuth } from "../../module/auth/core/Auth";

const EditProfile = () => {
  const initialValues: EditUserProfileModel = {
    id: 0,
    firstName: null,
    surname: null,
    dob: null,
    gender: null,
    weight: null,
    height: null,
    address: null,
    landmark: null,
    pincode: null,
    city: null,
    state: null,
    mobile: null,
    image: null,
    fileBase64Data: null,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required("Please enter your first name"),
    surname: Yup.string().trim().required("Please enter your surname"),
    dob: Yup.date().required("Please select your date of birth"),
    weight: Yup.string()
      .required("Please enter weight"),
    gender: Yup.string()
      .required("Please select gender"),
    height: Yup.string()
      .required("Please enter height"),
    pincode: Yup.string()
      .nullable()
      .min(6, 'Please enter 6 Digit Pincode')
      .max(6, 'Please enter 6 Digit Pincode')
      .matches(/^\d{6}$/),
  });

  const getUserProfile = usePostQuery("getUserProfile", GetUserProfile);
  const editUserProfile = usePutQuery("editUserProfile", EditUserProfile);
  const generateOtpQuery = usePostQuery("generateOtpQuery", GenerateOtp, {});
  const validateOtpQuery = usePostQuery("validateOtpQuery", ValidateOtp, {});

  const getAllStatesQuery: any = useGetQuery(
    "getAllStatesById",
    GetAllStatesById
  );

  const OTPRequestType = "ChangeNumber";
  const [isMobileNumberEdited, setIsMobileNumberEdited] = useState(false);
  const [imagesrc, setImageSrc] = useState<any>();
  const [visible, setVisible] = useState<any>(false);
  const [selectFileBase64, setSelectFileBase64] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [showCropperModal, setShowCropperModal] = useState(false);
  const fileInputprofileRef = useRef<any>(null);
  const [mobilenumber, setMobileNumber] = useState<any>(null);
  const [mobileIsError, setMobileIsError] = useState(false);
  const [submitIsClick, setSubmitIsClick] = useState(false);
  const [otp, setOtp] = useState(null);
  const [hideValid, setHideValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState<any>(180);
  const [otpOnceSentClick, setOtpOnceSentClick] = useState(false);
  const [otpValidated, setOtpValidated] = useState(true);
  const { saveAuth, setCurrentUser, logout } = useAuth();
  useEffect(() => {
    getUserProfile.mutate({});
    getAllStatesQuery.mutate(101);
  }, []);

  const [editUserProfileModel, setEditUserProfileModel] =
    useState<EditUserProfileModel>(initialValues);
  const allowedExtensions = ["jpg", "jpeg", "png"];

  const navigate = useNavigate();

  useEffect(() => {
    if (getUserProfile.isSuccess) {
      const getUser: EditUserProfileModel = getUserProfile?.data?.result;
      if (getUser.image != null && getUser.image !== undefined) {
        var selectImage = getUser.image;
        setSelectedImage(selectImage);
      } else {
        setSelectedImage(USER_DEFAULT_IMAGE_PATH);
      }
      if (getUser.mobile != null && getUser.mobile !== undefined) {
        let selectMobile: any = getUser.mobile;
        setMobileNumber(selectMobile);
      } else {
        // Handle case where getUser.mobile is null or undefined
        setMobileNumber(null); // Set mobile number to an empty string or any default value you prefer
      }
    }
  }, [getUserProfile.isSuccess]);

  const handleImageUpload = async (croppedImageData: Promise<any>) => {
    try {
      const imageData = await croppedImageData;
      setSelectFileBase64(imageData);
      setSelectedImage(imageData);
      setVisible(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            setImageSrc(event?.target?.result.toString());
          }
        };
        reader.readAsDataURL(file);
        setShowCropperModal(true);
      } else {
        toastify("warning", "Please select only image jpg, jpeg, png, gif");
        return;
      }
    }
    fileInputprofileRef.current.value = null;
  };

  const handleChangeMobile = (e: any) => {
    setIsMobileNumberEdited(true);
    setMobileNumber(e.target.value);
  };

  useEffect(() => {
    if (submitIsClick && (!mobilenumber || mobilenumber.length < 10)) {
      setMobileIsError(true);
    } else {
      setMobileIsError(false);
    }
  }, [mobilenumber, submitIsClick])

  const handleValidateOTP = (e: any) => {
    setHideValid(true);
    setOtp(e.target.value);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (otpSent && Number(resendTimer) > 0) {
      interval = setInterval(() => {
        setResendTimer(
          (prevTimer: any) => prevTimer - 1
          // const newTimer = Number(prevTimer) - 1;
          // return newTimer < 10 ? `0${newTimer}` : newTimer.toString();
        );
      }, 1000); // Change this to 180000 for a 3-minute timer
    }
    return () => clearInterval(interval);
  }, [otpSent, resendTimer]);

  useEffect(() => {
    if (Number(resendTimer) === 0) {
      setOtpSent(false);
      setResendTimer(180);
    }
  }, [resendTimer]);

  const formatTimer = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleGenerateOtp = () => {
    if (!otpSent) {
      const newRecord = {
        mobile: mobilenumber,
        otpRequestType: OTPRequestType,
        deviceType: "",
      };

      generateOtpQuery.mutate(newRecord, {
        onSuccess: (data) => {
          // setSignUpMobile(newRecord.mobile);
          setOtpSent(true);
          setIsMobileNumberEdited(true);
          // setGlobalErrors({
          //   mobile: "",
          // });
          toastify("success", "OTP sent successfully!");
          !otpOnceSentClick && setOtpOnceSentClick(true);
        },
        onError: (error) => {
          const errorMessage =
            error.response?.data?.responseException?.exceptionMessage ||
            "An error occurred while generating OTP.";
          console.error("Error generating OTP:", error);
          toastify("error", errorMessage);
        },
      });
    }
  };
  const validateOtpRef = useRef<HTMLInputElement>(null);
  const handlevalidateOtp = async () => {
    if (otpSent) {
      // setLoading(true);
      const otpData = {
        mobile: mobilenumber,
        otpCode: otp,
        otpRequestType: OTPRequestType,
        deviceType: "",
      };
      validateOtpQuery.mutate(otpData, {
        onSuccess: (data) => {
          toastify("success", "OTP validated successfully!");
          setResendTimer(null);
          setIsMobileNumberEdited(false);
          setOtp(null);
          if (validateOtpRef && validateOtpRef.current) validateOtpRef.current.value = "";
        },
        onError: (error) => {
          console.error("Error validating OTP:", error);
          toastify(
            "error",
            generateOtpQuery?.error?.response?.data?.responseException
              ?.exceptionMessage
          );
        },
        onSettled: () => {
          // setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    if (validateOtpQuery?.isSuccess) {
      toastify("success", "OTP validated successfully!");
      setOtpValidated(true);
    } else if (validateOtpQuery?.isError) {
      toastify(
        "error",
        validateOtpQuery?.error?.response?.data?.responseException
          ?.exceptionMessage
      );
      setOtpValidated(false);
    }
  }, [validateOtpQuery?.isSuccess, validateOtpQuery?.isError]);

  const onSubmit = (values: any) => {
    editUserProfileModel.id = parseInt(values.id);
    editUserProfileModel.image = selectFileBase64;
    editUserProfileModel.firstName = values.firstName;
    editUserProfileModel.surname = values.surname;
    editUserProfileModel.dob = values.dob;
    editUserProfileModel.gender = values.gender;
    editUserProfileModel.weight = values.weight;
    editUserProfileModel.height = values.height;
    editUserProfileModel.address = values.address;
    editUserProfileModel.landmark = values.landmark;
    editUserProfileModel.pincode = values.pincode;
    editUserProfileModel.city = values.city;
    editUserProfileModel.state = values.state;
    editUserProfileModel.mobile = mobilenumber;
    editUserProfileModel.fileBase64Data = selectFileBase64;

    if (editUserProfileModel?.id > 0) {
      if (
        selectedImage === USER_DEFAULT_IMAGE_PATH &&
        getUserProfile.data.result?.image != null
      ) {
        editUserProfileModel.fileBase64Data = null;
      }
      if (
        selectedImage !== USER_DEFAULT_IMAGE_PATH &&
        getUserProfile.data.result?.image != null
      ) {
        editUserProfileModel.image = getUserProfile.data.result?.image;
      }
      editUserProfileModel.image =
        editUserProfileModel.image === "" ? null : editUserProfileModel.image;
      if (selectedImage === USER_DEFAULT_IMAGE_PATH) {
        editUserProfileModel.image = null;
      }
      editUserProfileModel.image =
        editUserProfileModel.image === "" ? null : editUserProfileModel.image;
    }
    setEditUserProfileModel({ ...editUserProfileModel });
    if (!mobileIsError) {
      if (!isMobileNumberEdited && otpValidated) {
        editUserProfile.mutate(editUserProfileModel);
      } else {
        toastify("error", "Please validate OTP before submitting the form.");
      }
    }
  };

  useEffect(() => {
    if (editUserProfile?.isSuccess) {
      toastify("success", "profile updated successfully");
      setAuth(editUserProfile?.data?.result);
      saveAuth(editUserProfile?.data?.result);
      setCurrentUser(editUserProfile?.data?.result);
      const returnUrl = sessionStorage.getItem('returnUrl') || '/';
      if (returnUrl) {
        navigate(returnUrl);
        sessionStorage.removeItem('returnUrl');
      }
    }
  }, [editUserProfile.isSuccess, navigate]);


  return (
    <>
      <main>
        <section>
          <Container>
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-1 p-0">
                  <PageNavigation currentPage={"Edit Profile"} />
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className="editprofilebackgroundimage">
          <Container>
            <div className="px-md-5 pb-md-1 p-0">
              <div className="row">
                <div className="col-12">
                  <Formik
                    initialValues={editUserProfileModel}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={false}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      getFieldProps,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      useEffect(() => {
                        if (getUserProfile.isSuccess && !isSubmitting) {
                          const userProfileData = getUserProfile.data?.result;
                          if (userProfileData) {
                            setFieldValue("id", getUserProfile.data?.result.id);
                            setFieldValue("image", getUserProfile.data?.result.image);
                            setFieldValue(
                              "firstName",
                              getUserProfile.data?.result.firstName
                            );
                            setFieldValue(
                              "surname",
                              getUserProfile.data?.result.surname
                            );
                            setFieldValue(
                              "dob",
                              new Date(getUserProfile.data?.result.dob)
                            );
                            setFieldValue(
                              "gender",
                              getUserProfile.data?.result.gender
                            );
                            setFieldValue(
                              "weight",
                              getUserProfile.data?.result.weight
                            );
                            setFieldValue(
                              "height",
                              getUserProfile.data?.result.height
                            );
                            setFieldValue(
                              "address",
                              getUserProfile.data?.result.address
                            );
                            setFieldValue(
                              "landmark",
                              getUserProfile.data?.result.landmark
                            );
                            setFieldValue(
                              "pincode",
                              getUserProfile.data?.result.pincode
                            );
                            setFieldValue("city", getUserProfile.data?.result.city);
                            setFieldValue("state", getUserProfile.data?.result.state);
                            setFieldValue(
                              "mobile",
                              getUserProfile.data?.result.mobile
                            );
                          }
                        }
                      }, [getUserProfile.isSuccess]);

                      return (
                        <>
                          <form onSubmit={handleSubmit} noValidate>
                            <Row>
                              <div className="fs-1 bebas-neue-font text-default-color text-center">
                                <ImageUpload
                                  entityLabel={"image"}
                                  name={"image"}
                                  imagesrc={imagesrc}
                                  showCropperModal={showCropperModal}
                                  setShowCropperModal={setShowCropperModal}
                                  containerStyle={{
                                    position: "relative",
                                    width: "100%",
                                    height: 300,
                                    background: "#333",
                                  }}
                                  onComplete={handleImageUpload}
                                />
                              </div>
                              <div className="d-flex flex-column align-items-center mb-3">
                                {selectedImage && (
                                  <img
                                    alt="not found"
                                    style={{ width: "150px", height: "150px" }}
                                    src={selectedImage}
                                    onError={(ele) => {
                                      ele.currentTarget.src = toAbsoluteUrl(
                                        USER_DEFAULT_IMAGE_PATH
                                      );
                                    }}
                                    className="rounded-circle img-fluid"
                                    loading="lazy"
                                  />
                                )}
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                  ref={fileInputprofileRef}
                                  id="image-upload-input"
                                />
                                <label
                                  htmlFor="image-upload-input"
                                  className="text-black text-decoration-underline py-3 fw-400 font-18"
                                  style={{ cursor: "pointer" }}
                                  onChange={handleFileChange}
                                >
                                  Change Profile Photo
                                </label>
                              </div>
                            </Row>
                            <Row>
                              <Col className="pb-2 col-sm-6">
                                <Row className="gap-4">
                                  <div className="col-12">
                                    <div
                                      className="card rounded-4"
                                      style={{
                                        background: "rgb(240, 240, 240)",
                                        border: "none",
                                      }}
                                    >
                                      <div className="card-body">
                                        <h5 className="card-title text-uppercase pb-2 bebas-neue-font">
                                          Basic Information
                                        </h5>
                                        <Row className=" g-3 mb-3">
                                          <Form.Group className="col-md-6">
                                            <Form.Control
                                              type="text"
                                              {...getFieldProps("firstName")}
                                              className={`form-control ${errors.firstName && touched.firstName
                                                ? "border border-danger mb-0"
                                                : ""
                                                }`}
                                              onChange={handleChange}
                                              // value={values.firstName || :""}
                                              name="firstName"
                                              placeholder="First Name"
                                            />
                                          </Form.Group>
                                          <Form.Group className="col-md-6">
                                            <Form.Control
                                              type="text"
                                              {...getFieldProps("surname")}
                                              className={`form-control ${errors.surname && touched.surname
                                                ? "border border-danger mb-0"
                                                : ""
                                                }`}
                                              onChange={handleChange}
                                              // value={values.surname || ""}
                                              name="surname"
                                              placeholder="Surname"
                                            />
                                          </Form.Group>
                                        </Row>

                                        <div className="col-12 mb-3">
                                          <Form.Group className="input-group">
                                            <Form.Control
                                              type="text"
                                              // name="weight"
                                              placeholder="Weight"
                                              aria-label="Weight"
                                              {...getFieldProps("weight")}
                                              // value={values.weight || ""}
                                              onChange={handleChange}
                                              aria-describedby="basic-addon2"
                                              style={{ borderRight: "0px" }}
                                              className={`form-control ${errors.weight && touched.weight
                                                ? "border border-danger"
                                                : ""
                                                }`}
                                            />
                                            <div className="input-group-append">
                                              <span
                                                className="input-group-text"
                                                id="basic-addon2"
                                                style={{
                                                  borderLeft: "0px",
                                                  backgroundColor: "white",
                                                }}
                                              >
                                                KG
                                              </span>
                                            </div>
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <Form.Group className="input-group">
                                            <Form.Control
                                              type="text"
                                              placeholder="Height"
                                              aria-label="Height"
                                              {...getFieldProps("height")}
                                              // value={values.height || ""}
                                              onChange={handleChange}
                                              aria-describedby="basic-addon2"
                                              style={{ borderRight: "0px" }}
                                              className={`form-control ${errors.height && touched.height
                                                ? "border border-danger mb-0"
                                                : ""
                                                }`}
                                            />
                                            <div className="input-group-append">
                                              <span
                                                className="input-group-text"
                                                id="basic-addon2"
                                                style={{
                                                  borderLeft: "0px",
                                                  backgroundColor: "white",
                                                }}
                                              >
                                                CM
                                              </span>
                                            </div>
                                          </Form.Group>
                                        </div>
                                        <Row className="g-3">
                                          <div className="col-md-6">
                                            <Form.Group className="input-group">
                                              <Field
                                                id="dob"
                                                component={ReactDatePicker}
                                                maxDate={new Date()}
                                                dateFormat="MM/dd/yyyy"
                                                placeholderText="Select Date of Birth"
                                                {...getFieldProps("dob")}
                                                // value={values.dob || ""}
                                                selected={values.dob}
                                                onChange={(date: any) =>
                                                  setFieldValue("dob", date)
                                                }
                                                className={`form-control ${errors.dob && touched.dob
                                                  ? "border border-danger"
                                                  : ""
                                                  }`}
                                              />
                                            </Form.Group>
                                          </div>
                                          <div className="col-md-6">
                                            <Form.Group className="input-group">
                                              <Form.Check
                                                inline
                                                label="Male"
                                                {...getFieldProps("gender")}
                                                value="M"
                                                className={`${errors.gender &&
                                                  touched.gender && 'text-danger'}`}
                                                // onChange={handleChange}
                                                type="radio"
                                                id="male"
                                                checked={values.gender === "M"}
                                                onChange={() =>
                                                  setFieldValue("gender", "M")
                                                }
                                              />
                                              <Form.Check
                                                inline
                                                label="Female"
                                                {...getFieldProps("gender")}
                                                className={`${errors.gender &&
                                                  touched.gender && 'text-danger'}`}
                                                value="F"
                                                // onChange={handleChange}
                                                type="radio"
                                                id="female"
                                                checked={values.gender === "F"}
                                                onChange={() =>
                                                  setFieldValue("gender", "F")
                                                }
                                              />
                                            </Form.Group>
                                          </div>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div
                                      className="card rounded-4"
                                      style={{
                                        background: "rgb(240, 240, 240)",
                                        border: "none",
                                      }}
                                    >
                                      <div className="card-body">
                                        <h5 className="card-title text-uppercase pb-2 bebas-neue-font">
                                          Change Mobile Number
                                        </h5>
                                        <div className="col-12 mb-3">
                                          <Form.Group className="input-group">
                                            <Form.Control
                                              type="text"
                                              // name="mobileNumber"
                                              placeholder="Enter Mobile Number"
                                              {...getFieldProps("mobile")}
                                              className={`form-control ${mobileIsError
                                                ? "border border-danger"
                                                : ""
                                                }`}
                                              onChange={(e: any) => {
                                                const value = e.target.value;
                                                if (e.target.value.length < 11) {
                                                  if (/^\d*$/.test(value)) {
                                                    handleChangeMobile(e);
                                                  }
                                                }
                                              }
                                              }
                                              value={mobilenumber || ""}
                                              style={{ borderRight: "0px" }}
                                              disabled={otpSent}
                                            />

                                            <div className="input-group-append">
                                              <span
                                                className="input-group-text"
                                                id="basic-addon2"
                                                style={{
                                                  borderLeft: "0px",
                                                  backgroundColor: "white",
                                                  cursor: "pointer",
                                                  pointerEvents: otpSent
                                                    ? "none"
                                                    : "auto",
                                                }}
                                                onClick={handleGenerateOtp}
                                              // disabled={otpSent}
                                              >
                                                {otpOnceSentClick
                                                  ? `Resend OTP`
                                                  : "Send OTP"}
                                              </span>
                                            </div>
                                          </Form.Group>
                                          <div className="mb-3">
                                            {otpSent && resendTimer
                                              ? `Resend OTP in ${formatTimer(
                                                resendTimer
                                              )}`
                                              : ""}
                                          </div>
                                        </div>

                                        <div className="col-12">
                                          <Form.Group className="input-group">
                                            <Form.Control
                                              type="text"
                                              className={
                                                otpSent && isMobileNumberEdited
                                                  ? "form-control border border-danger"
                                                  : "form-control"
                                              }
                                              ref={validateOtpRef}
                                              id="OTP"
                                              maxLength={4}
                                              {...getFieldProps("otp")}
                                              onChange={handleValidateOTP}
                                              placeholder="Enter OTP"
                                              disabled={!otpSent}
                                            />
                                            {otp && (
                                              <div className="input-group-append">
                                                <span
                                                  className="input-group-text"
                                                  id="basic-addon2"
                                                  style={{
                                                    borderLeft: "0px",
                                                    backgroundColor: "white",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={handlevalidateOtp}
                                                >
                                                  Validate OTP
                                                </span>
                                              </div>
                                            )}
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                              <Col className="pb-2 col-sm-6">
                                <div
                                  className="card rounded-4"
                                  style={{
                                    background: "rgb(240, 240, 240)",
                                    border: "none",
                                  }}
                                >
                                  <div className="card-body">
                                    <h5 className="card-title text-uppercase pb-2 bebas-neue-font">
                                      Address
                                    </h5>
                                    <div className="row g-3">
                                      <Form.Group className="col-md-12">
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          id="address"
                                          {...getFieldProps("address")}
                                          value={values.address || ""}
                                          placeholder="Flat,house,apartment number etc."
                                        />
                                      </Form.Group>
                                      <Form.Group className="col-md-12">
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          id="landmark"
                                          {...getFieldProps("landmark")}
                                          placeholder="Socity, locality Or landmark"
                                        />
                                      </Form.Group>
                                      <Form.Group className="col-md-12">
                                        <Form.Control
                                          type="text"
                                          // className="form-control"
                                          id="pincode"
                                          {...getFieldProps("pincode")}
                                          className={`form-control ${errors.pincode && touched.pincode
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                              if (value.length <= 6) {
                                                handleChange(e);
                                              }
                                            }
                                          }}
                                          placeholder="Pincode"
                                        />
                                      </Form.Group>
                                      <div className="col-12">
                                        <Form.Group className="input-group">
                                          <Form.Control
                                            type="text"
                                            className="form-control"
                                            placeholder="City"
                                            aria-label="City"
                                            {...getFieldProps("city")}
                                            onChange={handleChange}
                                            aria-describedby="basic-addon2"
                                            style={{ borderRight: "0px" }}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div className="col-12">
                                        <Form.Group className="input-group">
                                          <Form.Select
                                            className="form-control"
                                            style={{ borderRight: "0px" }}
                                            aria-label="State"
                                            {...getFieldProps("state")}
                                            onChange={handleChange}
                                          >
                                            {getAllStatesQuery?.data?.result?.map(
                                              (item: any) => (
                                                <option
                                                  key={item.id}
                                                  value={item.name}
                                                >
                                                  {item.name}
                                                </option>
                                              )
                                            )}
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="p-5 row">
                              <div className="col-lg-12 col-md-12">
                                <div className=" text-center">
                                  <Button
                                    type="submit"
                                    className="rounded-pill btn btn-default btn-lg save-padding border btnNavLink  btn-getapp text-dark fw-500 font-20"
                                    onClick={() => {
                                      setSubmitIsClick(true)
                                    }}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main >
    </>
  );
};
export default EditProfile;
