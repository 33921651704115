import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import SignupLogin from "./components/SignUp/SignupLogin";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const AuthPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/media/assets/Auth/auth_background_1.jpg",
    "/media/assets/Auth/auth_background_2.jpg",
    "/media/assets/Auth/auth_background_3.jpg",
  ];

  const preloadImages = () => {
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  };

  useEffect(() => {
    preloadImages();
  }, []);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextImage, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Carousel
        fade
        controls={false}
        interval={null}
        indicators={false}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
        activeIndex={currentImageIndex}
        onSelect={(index) => setCurrentImageIndex(index)}
      >
        {images.map((imageUrl, index) => (
          <Carousel.Item key={index}>
            <div
              className="item img-fluid "
              style={{
                backgroundImage: `url(${imageUrl})`,
                width: "100vw",
                height: "100vh",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="container-fluid background-slider     ">
        <div className="row min-vh-100 d-flex align-items-center justify-content-center ">
          <div
            className="col-xl-5  col-lg-4 col-sm-5 col-md-9 d-flex align-items-center justify-content-center fade-left"
            style={{ position: "relative", zIndex: "1" }}
          >
            <Link to={"/"} className="cursor-pointer"><img
              src="/media/assets/Auth/move-logo.png"
              alt="Move"
              className="img-fluid move-logo p-lg-4 p-xxl-2 p-md-5"
              style={{
                width: "338.64px",
              }}
              loading="lazy"
            />
            </Link>
          </div>
          <div className="col-xl-7 col-lg-8 col-sm-7  col-md-9 d-flex align-items-center justify-content-center fade-right">
            <SignupLogin />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthPage;
