const SecondBlog = () => {
  return (
    <>
      <div className="row pb-md-4">
        <div className="col-12">
          <span className="fw-d-din-700 font-42">
            Why Big Corporates Are Betting on Corporate Wellness Programs for a
            Healthy Workforce
          </span>
        </div>
        <div className="col-12">
          <span className="fw-d-din-700 font-35">
            The Growing Trend of Corporate Wellness
          </span>
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-md-8 col-xl-6">
          <div className="row">
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="fw-500 text-9BA1A2 font-20">
                  <span>Written By</span>
                </div>
                <div>
                  <span className="pe-2">
                    <img src="/media/assets/user/blog-user-image.jpeg" alt="blog-profile" width={30} className="rounded-4" loading="lazy" />
                  </span>
                  <span className="fw-600 font-20 text-111827">
                    Mehul Adajaniya
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div>
                  <span className="font-20 text-9BA1A2 fw-500">Publish On</span>
                </div>
                <div>
                  <span className="font-20 text-111827 fw-600">
                    2 Jun 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-4">
        <div className="d-flex justify-content-center">
          <img
            src="/media/assets/blogs/blog_2.png"
            className="img-fluid"
            alt="blog-2"
            loading="lazy"
          />
        </div>
      </div>
      <div className="row text-9BA1A2 px-md-3">
        <p className="font-18 fw-400">Hello everyone,</p>
        <p className="font-18 fw-400 text-justify">
          I’m Mehul Adajaniya, founder of Move Wellness Solutions. Today, I want
          to delve into a topic that’s gaining tremendous traction in the
          corporate world – the rise of corporate wellness programs. As someone
          who’s deeply invested in promoting health and wellness, I’ve witnessed
          the transformative power these programs can have on organizations.
          Let’s explore why big corporates are increasingly investing in
          corporate wellness and the profound benefits they bring.
        </p>
      </div>
      <div className="list-group-item d-flex justify-content-between align-items-start border-0">
        <div className="ms-2 me-auto">
          <div className="fw-500 font-25">
            The Growing Trend of Corporate Wellness
          </div>
          <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
            Corporate wellness programs are no longer just a perk; they’re
            becoming an integral part of company culture. Businesses worldwide
            recognize that the health and well- being of their employees
            directly impact productivity, engagement, and overall success.
            Here’s why major corporations are betting big on these programs:
          </div>
        </div>
      </div>
      <div className="row mb-2 px-md-3">
        <ol className="list-group list-group-numbered border-0 fw-500 font-25 ">
          <li className="list-group-item d-flex justify-content-between align-items-start border-0 ">
            <div className="ms-2 me-auto">
              <div className="">Enhanced Productivity</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Healthy employees are productive employees. Regular physical
                activity and wellness practices boost energy levels, improve
                focus, and enhance cognitive function. When employees feel good
                physically and mentally, they’re more likely to perform at their
                best. This increased productivity directly contributes to a
                company’s bottom line, making wellness programs a smart
                investment.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Reduced Healthcare Costs</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Healthcare costs are a significant concern for businesses. By
                investing in wellness programs, companies can reduce these costs
                in the long run. Healthy employees are less likely to suffer
                from chronic illnesses, reducing the need for medical
                interventions and lowering insurance premiums. Preventive care
                through wellness programs is far more cost-effective than
                treating illnesses after they develop.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Lower Absenteeism</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Absenteeism can be a major disruptor in the workplace. Wellness
                programs help employees stay healthy and reduce the incidence of
                sick days. Regular exercise, proper nutrition, and stress
                management are key components of these programs that keep
                employees fit and less prone to illnesses. As a result,
                companies experience lower absenteeism rates, ensuring smoother
                operations.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">
                Improved Employee Morale and Engagement
              </div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Wellness programs show employees that their well-being is a
                priority. This fosters a sense of loyalty and commitment to the
                company. When employees feel valued and supported, their morale
                and engagement levels soar. They’re more likely to be motivated,
                satisfied with their jobs, and committed to their roles. High
                morale translates into a positive work environment and better
                overall performance.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">
                Attracting and Retaining Top Talent
              </div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                In today’s competitive job market, companies need to offer more
                than just a good salary to attract top talent. Prospective
                employees look for organizations that prioritize their
                well-being. Comprehensive wellness programs can be a deciding
                factor for candidates when choosing an employer. Additionally,
                these programs help retain existing employees by promoting a
                healthier and more satisfying work-life balance.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">
                Fostering a Healthy Workplace Culture
              </div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Corporate wellness programs go beyond individual health
                benefits; they create a culture of wellness. Group activities
                such as fitness challenges, workshops, and wellness events
                foster team spirit and camaraderie. These activities bridge gaps
                across different levels of the organization, promoting a sense
                of unity and collaboration. A healthy workplace culture is not
                only beneficial for employees but also for the organization’s
                reputation.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Long-Term Business Success</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Ultimately, the health and well-being of employees are directly
                linked to the long-term success of a business. Healthy employees
                are more resilient, adaptable, and capable of handling stress.
                They contribute to a positive work environment, driving
                innovation and growth. Companies that invest in wellness
                programs are investing in the future success and sustainability
                of their business.
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div className="row text-9BA1A2 px-md-3 text-justify lh-25 mb-2">
        <p className="font-18 fw-400">
          At Move Wellness Solutions, we understand the critical role corporate
          wellness programs play in fostering a healthy workforce. Our tailored
          programs are designed to meet the specific needs of organizations,
          offering structured challenges and activities that promote physical
          and mental well-being. We believe that a healthy workforce is a happy
          and productive one, and we’re committed to helping companies achieve
          this goal.
        </p>
        <p className="font-18 fw-400">
          If your organization is looking to enhance productivity, reduce
          healthcare costs, and create a thriving workplace culture, it’s time
          to invest in corporate wellness. Join us in our mission to move
          towards a healthier, fitter future.
        </p>
        <p className="font-18 fw-400">Stay active and stay healthy,</p>
        <p className="mb-1 text-black fw-500 font-20">Mehul Adajaniya</p>
        <p className="fw-500 font-20">Founder & CEO, Move Wellness Solutions</p>
      </div>
    </>
  );
};

export default SecondBlog;
