const FirstBlog = () => {
    return (
        <>

            <div className="row pb-md-4">
                <div className="col-12">
                    <span className="fw-d-din-700 font-42">
                        How Virtual Running and Cycling Events Keep You Healthy
                    </span>
                </div>
            </div>
            <div className="row pb-4">
                <div className="col-md-8 col-xl-6">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="d-flex flex-column">
                                <div className="fw-500 text-9BA1A2 font-20">
                                    <span>
                                        Written By
                                    </span>
                                </div>
                                <div>
                                    <span className="pe-2">
                                        <img src="/media/assets/user/blog-user-image.jpeg" alt="blog-profile" width={30} className="rounded-4" loading="lazy" />
                                    </span>
                                    <span className="fw-600 font-20 text-111827">
                                        Mehul Adajaniya
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="d-flex flex-column">
                                <div>
                                    <span className="font-20 text-9BA1A2 fw-500">
                                        Publish On
                                    </span>
                                </div>
                                <div>
                                    <span className="font-20 text-111827 fw-600">
                                        1 Jun 2024
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pb-4">
                <div className="d-flex justify-content-center">
                    <img src="/media/assets/blogs/blog_1.png" className="img-fluid " alt="blogimage-1" loading="lazy" />
                </div>
            </div>
            <div className="row text-9BA1A2 px-md-3">
                <p className="font-18 fw-400">
                    Hello everyone,
                </p>
                <p className="font-18 fw-400 text-justify">
                    I’m Mehul Adajaniya, founder of Move Wellness Solutions. Today, I want to share my thoughts on how virtual running and cycling
                    events can play a significant role in keeping you healthy and fit. As someone deeply passionate about fitness and wellness, I’ve
                    seen firsthand the incredible impact that regular physical activity can have on our lives. With the rise of technology, we now
                    have the opportunity to bring fitness into our homes and connect with a global community of like-minded individuals. Here’s
                    how virtual events can help you stay healthy:

                </p>
            </div>
            <div className="row mb-2 px-md-3">
                <ol className="list-group list-group-numbered border-0 fw-500 font-25 ">
                    <li className="list-group-item d-flex justify-content-between align-items-start border-0 ">
                        <div className="ms-2 me-auto">
                            <div className="">Convenience and Accessibility</div>
                            <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                                One of the biggest barriers to regular exercise is finding the time and place to do it. Virtual running and cycling events eliminate
                                this obstacle by allowing you to participate from anywhere. Whether you’re at home, in a park, or on a business trip, all you need
                                is your device and a bit of motivation. This flexibility ensures that you can stay consistent with your fitness routine, no matter how
                                busy your schedule gets.
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start border-0">
                        <div className="ms-2 me-auto">
                            <div className="fw-500 font-25">Motivation Through Community</div>
                            <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                                Exercising alone can sometimes feel like a chore, but when you’re part of a larger community, it becomes a shared experience.
                                Virtual events connect you with participants from around the world, creating a sense of camaraderie and competition. You’ll
                                find yourself motivated by the achievements of others, and in turn, your progress will inspire them. It’s a powerful cycle of
                                encouragement and support that keeps everyone moving forward.
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start border-0">
                        <div className="ms-2 me-auto">
                            <div className="fw-500 font-25">Goal Setting and Achievement</div>
                            <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                                Setting goals is crucial for any fitness journey. Virtual events provide a structured environment where you can set, track, and
                                achieve your goals. Whether it’s completing a marathon, cycling a certain distance, or simply improving your personal best,
                                having a clear objective helps you stay focused and committed. The sense of accomplishment you feel when you reach your
                                goals is incredibly rewarding and keeps you motivated to set new ones.
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start border-0">
                        <div className="ms-2 me-auto">
                            <div className="fw-500 font-25">Health Benefits</div>
                            <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                                The health benefits of regular running and cycling are well-documented. These activities improve cardiovascular health,
                                boost immune function, enhance mental well-being, and help manage weight. Virtual events ensure that you’re getting these
                                benefits consistently. The convenience and accessibility mean you’re more likely to stick with your routine, leading to long-term
                                health improvements.
                            </div>

                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start border-0">
                        <div className="ms-2 me-auto">
                            <div className="fw-500 font-25">Exciting Rewards</div>
                            <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                                At Move, we believe in celebrating your achievements. Our virtual events come with exciting rewards such as t-shirts, medals,
                                and other exclusive merchandise. These tangible rewards serve as a reminder of your hard work and dedication, and they
                                motivate you to keep pushing your limits.
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
            <div className="row text-9BA1A2 px-md-3 text-justify lh-25 mb-2">
                <p className="font-18 fw-400">
                    In conclusion, virtual running and cycling events offer a unique and effective way to stay healthy. They break down barriers to
                    exercise, provide a supportive community, and keep you motivated with structured goals and exciting rewards. At Move,
                    we’re committed to making fitness accessible and enjoyable for everyone. Whether you’re a seasoned athlete or just starting
                    out, our virtual events are here to support you on your journey to better health.

                </p>
                <p className="font-18 fw-400">
                    Thank you for being a part of the Move community. Let’s keep moving towards a healthier, fitter future together.
                </p>
                <p className="font-18 fw-400">
                    Stay active and stay healthy,
                </p>
                <p className="mb-1 text-black fw-500 font-20">
                    Mehul Adajaniya
                </p>
                <p className="fw-500 font-20">
                    Founder & CEO, Move Wellness Solutions
                </p>
            </div>
        </>
    )
}

export default FirstBlog;