/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { usePostQuery, useTokenQuery } from "../../hooks/reactQueryHelper";
import {
  genetareToken,
  GetEventDetail,
  GetParticipatedEventUsers,
  GetUpcomingEvents,
  validateInvitedUser,
} from "../../services/Web.type";
import EventDetailsCard from "../../Common/Card/EventDetailsCard";
import CardSlide from "../../Common/Card/CardSlide";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import { useAuth } from "../../module/auth/core/Auth";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import EventDescriptionCard from "../../Common/Card/EventDescriptionCard";
import BlankPage from "../../Common/BlankData/BlankPage";
import { setAuth } from "../../module/auth/core/AuthHelper";
import {
  joinFreeEvent,
  joinPaidEvent,
  razorpayPayment,
} from "../../services/Web.type";
import Swal from "sweetalert2";

import { AppName, RazorPayKey } from "../../shared/constants";
import WebRazorpayResponseModel from "../../module/auth/core/_models";
import useRazorpay from "react-razorpay";

const PrivateEventDetails = () => {
  const { saveAuth, setCurrentUser, logout } = useAuth();
  const { slug, id } = useParams();
  const { auth } = useAuth();
  const [showOtherDetails, setShowOtherDetails] = useState(false);

  const [isFree, setIsFree] = useState(false);
  const [isSlotAvailable, setIsSlotAvailable] = useState(false);
  const [isUserjoinded, setIsUserjoinded] = useState(false);
  const [eventID, setEventID] = useState(0);
  const [activeTab, setActiveTab] = useState("eventDetails");
  const [inviteToken, setinviteToken] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);
  const location = useLocation();
  const additionalData: any = location.state;
  const [tabType, setTabType] = useState(additionalData ?? false);
  const webRazorpayResponseModel: WebRazorpayResponseModel = {
    orderID: null,
    amount: null,
    currency: null,
    logo: null,
    themeColor: null,
    eventID: null,
    description: null,
  };
  const [orderDetails, setorderDetails] = useState<WebRazorpayResponseModel>(
    webRazorpayResponseModel
  );

  const [Razorpay] = useRazorpay();

  const [isjoined, setIsjoined] = useState<boolean>(false);
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const fullUrl = `${GetEventDetail}/${slug}`;
  const fullURLGetToken = `${genetareToken}/${id}`;
  const navigate = useNavigate();

  const validateInvitedUserQuery = usePostQuery(
    "validateInvitedUser",
    validateInvitedUser
  );
  const genetareTokenUserQuery = usePostQuery(
    "genetareTokenUserQuery",
    fullURLGetToken
  );
  const getEventDetailQuery = usePostQuery("getEventDetailQuery", fullUrl);
  const isParticipatedEventUrl = `${GetParticipatedEventUsers}/${slug}`;
  const joinFreeEventQuery = usePostQuery("joinFreeEvent", joinFreeEvent);
  const joinPaidEventQuery = usePostQuery("joinPaidEvent", joinPaidEvent);
  const razorpayPaymentQuery = usePostQuery("razorpayPayment", razorpayPayment);

  const getParticipatedEventUsers = usePostQuery(
    "getParticipatedEventUsers",
    isParticipatedEventUrl
  );

  const successfullyPaymentAlert = () =>
    Swal.fire({
      title: "Successfully join event!",
      text: "",
      icon: "success",
      iconColor: "rgba(254, 204, 9, 1)",
      confirmButtonColor: "rgba(254, 204, 9, 1)",
      confirmButtonText: "Okay",
      showCancelButton: false,
      html: `
        <h6>To begin and monitor your event, the next step is to download the mobile app and continue engaging with event activities.</h6>
        <h4>Download the move App!</h4>
        <div style="display: flex; justify-content: center; gap: 20px; margin-top: 20px; ">
          <a href="https://play.google.com/store/apps/details?id=com.iamonthemove.move" target="_blank">
            <img src="/media/assets/events/google-play.png" alt="Google Play" style="cursor: pointer;" />
          </a>
          <a href="https://apps.apple.com/in/app/move-i-am-on-the-move/id6504929310" target="_blank">
            <img src="/media/assets/events/app-store.png" alt="App Store" style="cursor: pointer;" />
          </a>
        </div>
      `,
      customClass: {
        confirmButton:
          "rounded-pill fs-5 btn btn-default btn-lg px-5 py-2 border btnNavLink  btn-getapp text-dark fw-500 mt-3 b-0",
      },
      focusConfirm: true,
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id != null || id != undefined) {
          id.trim();
        }
        genetareTokenUserQuery.mutateAsync({ id: id });
      } catch (error) {
        navigate("/error/404");
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    debugger;
    if (genetareTokenUserQuery?.isSuccess) {
      setinviteToken(genetareTokenUserQuery?.data?.result?.token);
    }
  }, [genetareTokenUserQuery?.isSuccess]);

  useEffect(() => {
    if (genetareTokenUserQuery?.isError) {
      navigate("/error/404");
    }
  }, [genetareTokenUserQuery?.isError]);

  useEffect(() => {
    if (joinFreeEventQuery?.isSuccess) {
      navigate("/participations");
      successfullyPaymentAlert();
    }
  }, [joinFreeEventQuery?.isSuccess]);

  useEffect(() => {
    if (joinPaidEventQuery?.isSuccess) {
      setorderDetails(joinPaidEventQuery?.data?.result);
      const options = {
        key: RazorPayKey,
        amount: `${joinPaidEventQuery?.data?.result?.amount}`,
        currency: `${joinPaidEventQuery?.data?.result?.currency}`,
        name: `${AppName}`,
        order_id: `${joinPaidEventQuery?.data?.result?.orderID}`,
        image: `${joinPaidEventQuery?.data?.result?.logo}`,
        description: `${joinPaidEventQuery?.data?.result?.description}`,
        theme: { color: `${joinPaidEventQuery?.data?.result?.themeColor}` },
        // 'prefill': {'contact': user.mobile, 'email': user.email},
        handler: function (response: any) {
          razorpayPaymentQuery.mutate({
            userID: 0,
            eventID: eventID,
            transactionID: response.razorpay_payment_id,
            paymentStatus: 2,
            orderID: response.razorpay_order_id,
          });
          successfullyPaymentAlert();
          navigate("/participations");
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response: any) {
        Swal.fire({
          title: "Payment failed!",
          titleText: `${response.error.description}`,
          text: `Note down your transaction id : ${response.error.metadata.payment_id}`,
          icon: "error",
          confirmButtonColor: "rgba(254, 204, 9, 1)",
          confirmButtonText: "Okay",
          showCancelButton: false,
          customClass: {
            confirmButton:
              "rounded-pill fs-5 btn btn-default btn-lg px-5 py-2 border btnNavLink  btn-getapp text-dark fw-500 mt-3 b-0",
          },
          focusConfirm: true,
        });

        razorpayPaymentQuery.mutate({
          userID: 0,
          eventID: eventID,
          transactionID: response.error.metadata.payment_id,
          paymentStatus: 3,
          orderID: response.error.metadata.order_id,
          notes: response.error.description,
        });
      });

      rzp1.open();
    }
  }, [joinPaidEventQuery?.isSuccess]);

  useEffect(() => {
    if (razorpayPaymentQuery?.isSuccess) {
      navigate("/participations");
    }
  }, [razorpayPaymentQuery?.isSuccess]);

  useEffect(() => {
    const requestUser = async (inviteToken: string) => {
      try {
        validateInvitedUserQuery.mutate({ token: inviteToken });
      } catch (error) {
        console.log(error);
      }
    };
    if (inviteToken) {
      requestUser(inviteToken);
    }
  }, [inviteToken]);

  useEffect(() => {
    if (validateInvitedUserQuery?.isSuccess) {
      localStorage.clear();
      setAuth(validateInvitedUserQuery?.data?.result);
      saveAuth(validateInvitedUserQuery?.data?.result);
      setCurrentUser(validateInvitedUserQuery?.data?.result);


    }
  }, [validateInvitedUserQuery?.isSuccess]);

  useEffect(() => {
    if (validateInvitedUserQuery?.isError) {
      navigate("/error/404");
    }
  }, [validateInvitedUserQuery?.isError]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getEventDetailQuery.mutateAsync({});
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here
        navigate("/events");
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        await getParticipatedEventUsers.mutateAsync({});
      } catch (error) {
        console.log("error");
      }
    };
    if (auth) {
      fetchAuthData();
    }
  }, [auth]);

  useEffect(() => {
    if (auth && eventID > 0) {
      if (isFree) {
        joinFreeEventQuery.mutate({ id: eventID });
      } else {
        joinPaidEventQuery.mutate({ id: eventID });
      }
    }

  }, [auth, eventID, isFree]);

  useEffect(() => {
    if (getEventDetailQuery?.isSuccess) {
      if (getEventDetailQuery?.data?.result?.isValidInvitation > 0) {
        navigate('error/404');
      } else {
        setIsFree(getEventDetailQuery?.data?.result?.joiningFees == 0);
        setEventID(getEventDetailQuery?.data?.result?.id);
        setIsSlotAvailable(getEventDetailQuery?.data?.result?.isSlotAvailable);
      }
    }
  }, [getEventDetailQuery?.isSuccess]);

  useEffect(() => {
    if (getParticipatedEventUsers?.isSuccess) {
      setIsUserjoinded(
        getParticipatedEventUsers?.data?.result?.isParticipatedEvent
      );
    }
  }, [getParticipatedEventUsers?.isSuccess]);
  const getUpcomingEventQuery = usePostQuery(
    "getUpcomingEventQuery",
    GetUpcomingEvents,
    {}
  );

  useEffect(() => {
    getUpcomingEventQuery.mutate({
      search: "",
      eventTypeID: [],
      isGetAll: true,
    });
  }, []);

  useEffect(() => {
    if (
      getEventDetailQuery?.error &&
      (getEventDetailQuery.error.response?.status === 400 ||
        getEventDetailQuery.error.response?.status === 404 ||
        getEventDetailQuery.error.response?.status === 500)
    ) {
      navigate("/events");
    }
  }, [getEventDetailQuery?.error, navigate]);

  useEffect(() => {
    if (auth) {
      setShowOtherDetails(false);
    } else {
      setShowOtherDetails(true);
    }
  }, [auth]);

  useEffect(() => {
    const checkIsDesktop = () => {
      const desktopMediaQuery = window.matchMedia("(min-width: 1024px)");
      setIsDesktop(desktopMediaQuery.matches);
    };

    checkIsDesktop();

    // Add event listener for changes in screen size
    const resizeListener = () => {
      checkIsDesktop();
    };

    window.addEventListener("resize", resizeListener);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-3 p-0">
                  <PageNavigation currentPage={"Event Details"} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="px-md-5 pb-md-1 p-0">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex gap-3 mb-4">
                    {tabType && (
                      <>
                        <button
                          className={`d-lg-none fw-600 align-items-center border-0 background-light rounded-pill px-3 py-2 ${activeTab === "eventDetails"
                            ? "active bg-default text-black"
                            : "text-5F6368 bg-F3F3F3"
                            }`}
                          onClick={() => handleTabChange("eventDetails")}
                        >
                          Event details
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <>
                  {isDesktop ? (
                    <>
                      {
                        <EventDetailsCard
                          getEventDetailQuery={getEventDetailQuery}
                          slug={slug}
                          showOtherDetails={showOtherDetails}
                          isjoined={
                            isSlotAvailable == true && isUserjoinded == false
                          }
                        />
                      }
                      {
                        <EventDescriptionCard
                          getEventDetailQuery={getEventDetailQuery}
                          showOtherDetails={showOtherDetails}
                          isFree={isFree}
                          eventID={eventID}
                          isjoined={
                            isSlotAvailable == true && isUserjoinded == false
                          }
                          isPublic={false}
                        />
                      }
                    </>
                  ) : (
                    <>
                      {activeTab === "eventDetails" && (
                        <>
                          {
                            <EventDetailsCard
                              getEventDetailQuery={getEventDetailQuery}
                              slug={slug}
                              showOtherDetails={showOtherDetails}
                              isjoined={
                                isSlotAvailable == true &&
                                isUserjoinded == false
                              }
                            />
                          }
                          {
                            <EventDescriptionCard
                              getEventDetailQuery={getEventDetailQuery}
                              showOtherDetails={showOtherDetails}
                              isFree={isFree}
                              eventID={eventID}
                              isjoined={
                                isSlotAvailable == true &&
                                isUserjoinded == false
                              }
                              isPublic={false}
                            />
                          }
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-footer-light mt-5">
          <div className="container">
            <div className="px-md-5 pb-md-2 p-0">
              <div className="row">
                <div className="col-12">
                  <h1 className="fw-600 py-5 text-center mb-0 text-uppercase">
                    upcoming events
                  </h1>
                </div>
                <div className="col-12">
                  {getUpcomingEventQuery?.data?.result?.length > 0 ? (
                    <CardSlide
                      getUpcomingEventQuery={getUpcomingEventQuery}
                      pageName={"upcomingEvent"}
                      isdetailpage={false}
                    />
                  ) : (
                    <BlankPage />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-background ">
          <DownloadSection />
        </section>
      </main>
    </>
  );
};

export default PrivateEventDetails;
