/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "react-loading-skeleton/dist/skeleton.css";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import {
  GetActivity,
  GetActivityjournal,
  GetEventDetail,
  GetParticipatedEventUsers,
  GetUpcomingEvents,
} from "../../services/Web.type";
import EventDetailsCard from "../../Common/Card/EventDetailsCard";
import CardSlide from "../../Common/Card/CardSlide";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import { useAuth } from "../../module/auth/core/Auth";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import {
  Journal,
  UserActivitySession,
  WebJournalFilter,
  WebJournalResponseModel,
} from "../../module/auth/core/_models";
import MapComponent from "../../helper/MapComponent";
import EventDescriptionCard from "../../Common/Card/EventDescriptionCard";
import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import {
  GetDateTimeOffset,
  GetKilometers,
  getEventsessionTime,
} from "../../services/utility";
import { cycling, running, three_star, walking } from "../../shared/constants";
import BlankPage from "../../Common/BlankData/BlankPage";

const EventDetails = () => {
  const userActivityObject: UserActivitySession = {
    duration: null,
    calories: null,
    totalSteps: null,
    unit: null,
    timeUnit: null,
    participantName: null,
    age: null,
    distance: null,
    id: 0,
    eventTypeID: 0,
    userActivityHistories: null,
  };

  const { slug } = useParams();
  const { auth } = useAuth();
  const [showActivity, setShowActivity] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [showEventDescription, setShowEventDescription] = useState(false);
  const [showOtherDetails, setShowOtherDetails] = useState(false);

  const [isFree, setIsFree] = useState(false);
  const [eventID, setEventID] = useState(0);
  const [userActivitySession, setUserActivitySession] =
    useState<UserActivitySession>(userActivityObject);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("eventDetails");
  const [isDesktop, setIsDesktop] = useState(false);
  const location = useLocation();
  const additionalData: any = location.state;
  const [tabType, setTabType] = useState(additionalData ?? false);
  const [selectedEventTypes, setSelectedEventTypes] = useState<number>(0);
  const [isjoined, setIsjoined] = useState<boolean>(false);
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [journalFilter, setJournalFilter] = useState<WebJournalFilter>({
    endDate: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    id: 0,
    startDate: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    key: dateRange[0].key,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timezonOffset: GetDateTimeOffset(new Date()),
    eventTypeID: selectedEventTypes,
  });
  const fullUrl = `${GetEventDetail}/${slug}`;

  const isParticipatedEventUrl = `${GetParticipatedEventUsers}/${slug}`;

  const getEventDetailQuery = usePostQuery("getEventDetailQuery", fullUrl);
  const getActivityHistoryquery = usePostQuery("GetActivity", GetActivity);
  const getActivityquery = usePostQuery(
    "GetActivityjournal",
    GetActivityjournal,
    {}
  );

  const getParticipatedEventUsers = usePostQuery(
    "getParticipatedEventUsers",
    isParticipatedEventUrl
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getEventDetailQuery.mutateAsync({});
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here
        navigate("/events");
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    if (getEventDetailQuery?.isSuccess) {
      setIsFree(getEventDetailQuery?.data?.result?.joiningFees == 0);
      setEventID(getEventDetailQuery?.data?.result?.id);
      setIsjoined(
        getEventDetailQuery?.data?.result?.isSlotAvailable == true &&
        getEventDetailQuery?.data?.result?.isJoined == false
      );
    }
  }, [getEventDetailQuery?.isSuccess]);
  const getUpcomingEventQuery = usePostQuery(
    "getUpcomingEventQuery",
    GetUpcomingEvents,
    {}
  );

  useEffect(() => {
    getUpcomingEventQuery.mutate({
      search: "",
      eventTypeID: [],
      isGetAll: true,
    });
  }, []);

  useEffect(() => {
    if (
      getEventDetailQuery?.error &&
      (getEventDetailQuery.error.response?.status === 400 ||
        getEventDetailQuery.error.response?.status === 404 ||
        getEventDetailQuery.error.response?.status === 500)
    ) {
      navigate("/events");
    }
  }, [getEventDetailQuery?.error, navigate]);

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        await getParticipatedEventUsers.mutateAsync({});
        await getActivityquery.mutateAsync(journalFilter);
      } catch (error) {
        console.log("error");
      }
    };
    if (auth) {
      fetchAuthData();
    }
  }, [auth]);

  useEffect(() => {
    if (auth) {
      setShowActivity(true);
      setShowEventDetails(true);
      setShowEventDescription(true);
      setShowOtherDetails(false);
    } else {
      setShowEventDetails(true);
      setShowEventDescription(true);
      setShowActivity(false);
      setShowOtherDetails(true);
    }
  }, [auth]);

  const ButtonClickHandler = (id: any, buttonType: string) => {
    const fetchActivityData = async () => {
      try {
        await getActivityHistoryquery.mutateAsync({ id: id });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (buttonType === "Location") {
      if (id > 0) {
        fetchActivityData();
      }
      setIsOpenModal(false);
    }
  };
  useEffect(() => {
    if (getActivityHistoryquery.isSuccess) {
      setUserActivitySession(getActivityHistoryquery.data?.result);
      setIsOpenModal(true);
    }
  }, [getActivityHistoryquery.isSuccess]);

  useEffect(() => {
    const checkIsDesktop = () => {
      const desktopMediaQuery = window.matchMedia("(min-width: 1024px)");
      setIsDesktop(desktopMediaQuery.matches);
    };

    checkIsDesktop();

    // Add event listener for changes in screen size
    const resizeListener = () => {
      checkIsDesktop();
    };

    window.addEventListener("resize", resizeListener);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-3 p-0">
                  <PageNavigation currentPage={"Event Details"} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="px-md-5 pb-md-1 p-0">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex gap-3 mb-4">
                    {tabType && (
                      <>
                        <button
                          className={`d-lg-none fw-600 align-items-center border-0 background-light rounded-pill px-3 py-2 ${activeTab === "eventDetails"
                            ? "active bg-default text-black"
                            : "text-5F6368 bg-F3F3F3"
                            }`}
                          onClick={() => handleTabChange("eventDetails")}
                        >
                          Event details
                        </button>
                        <button
                          className={`d-lg-none  fw-600 align-items-center border-0 background-light rounded-pill px-3 py-2 ${activeTab === "activity"
                            ? "active bg-default text-black"
                            : " text-5F6368 bg-F3F3F3"
                            }`}
                          onClick={() => handleTabChange("activity")}
                        >
                          Activity
                        </button>
                        {/* <button
                    className={`d-lg-none align-items-center border-0 background-light rounded-pill px-3 py-2 ${activeTab === "ranking" ? "active bg-default text-black" : ""
                  }`}
                  style={{ fontWeight: "600" }}
                  onClick={() => handleTabChange("ranking")}
                  >
                  Ranking
                </button> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <>
                  {isDesktop ? (
                    <>
                      {showEventDetails && (
                        <EventDetailsCard
                          getEventDetailQuery={getEventDetailQuery}
                          slug={slug}
                          showOtherDetails={showOtherDetails}
                          isjoined={isjoined}
                        />
                      )}
                      {showEventDescription && (
                        <EventDescriptionCard
                          getEventDetailQuery={getEventDetailQuery}
                          showOtherDetails={showOtherDetails}
                          isFree={isFree}
                          eventID={eventID}
                          isjoined={isjoined}
                          isPublic={true}
                        />
                      )}
                      {tabType && showActivity ? (
                        <div className="px-3 mt-3">
                          <Row className="bg-F3F3F3 p-4  rounded-4 ">
                            <Col
                              sm={6}
                              className="col-md-12 border-bottom mb-2"
                            >
                              <div className="row card-title mb-0 h5">
                                <div className="d-flex gap-3 align-items-center mb-3">
                                  <img src={three_star} alt="three-star" loading="lazy" />
                                  <span className="card-title mb-0 fw-500 font-18">
                                    Automatically tracked event/challenge
                                    activity
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col sm={6} className="col-md-12">
                              <>
                                {getActivityquery?.data?.result?.journal
                                  ?.length > 0 ? (
                                  getActivityquery?.data?.result?.journal?.map(
                                    (history: any, index: number) => {
                                      return (
                                        <>
                                          {history?.activity?.length > 0 && (
                                            <>
                                              {history?.activity
                                                ?.slice(1, 2)
                                                ?.map((e: any) => {
                                                  return (
                                                    <>
                                                      <Col
                                                        lg={12}
                                                        className="col-md-12 mb-3"
                                                      >
                                                        <Row
                                                          className="my-2 cursor-pointer"
                                                          onClick={() =>
                                                            ButtonClickHandler(
                                                              e.id,
                                                              "Location"
                                                            )
                                                          }
                                                        >
                                                          <Col
                                                            sm={6}
                                                            className="col-md-7"
                                                          >
                                                            <Row>
                                                              <Col className="col-md-2">
                                                                <img
                                                                  src={
                                                                    e?.eventTypeName ===
                                                                      "Walking"
                                                                      ? walking
                                                                      : e?.eventTypeName ===
                                                                        "Cycling"
                                                                        ? cycling
                                                                        : e?.eventTypeName ===
                                                                        "Running" &&
                                                                        running
                                                                  }
                                                                  alt="eventType"
                                                                  width={50}
                                                                  loading="lazy"
                                                                />
                                                              </Col>
                                                              <Col className="col-md-5 d-flex justify-content-start align-items-center">
                                                                <span className="fw-bolder fs-6 plus-jakarta-sans-font text-color-black">
                                                                  {getEventsessionTime(
                                                                    e?.startTime,
                                                                    e?.eventTypeName
                                                                  )}
                                                                </span>
                                                              </Col>
                                                              <Col className="col-md-5 d-md-block d-none d-md-flex justify-content-center align-items-center">
                                                                <span className="plus-jakarta-sans-font fw-bolder fs-6 text-capitalize text-color-black">
                                                                  {
                                                                    e?.eventTypeName
                                                                  }
                                                                </span>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          <Col
                                                            sm={6}
                                                            className="col-md-5"
                                                          >
                                                            <Row>
                                                              <Col className="col-md-4 d-flex justify-content-center text-center">
                                                                <div className="d-flex flex-column">
                                                                  <div className="plus-jakarta-sans-font fw-medium text-5F6368 font-14">
                                                                    Distance
                                                                  </div>
                                                                  <div className="bebas-neue-font font-18">{`${GetKilometers(
                                                                    e?.distance
                                                                  )} KM`}</div>
                                                                </div>
                                                              </Col>
                                                              <Col className="col-md-4 d-flex  justify-content-center border-start border-end text-center">
                                                                <div className="d-flex flex-column">
                                                                  <div className="plus-jakarta-sans-font fw-medium text-5F6368 font-14">
                                                                    Pace
                                                                  </div>
                                                                  <div className="bebas-neue-font font-18">{`${e?.speed
                                                                    } ${e?.speed > 0
                                                                      ? `/KM`
                                                                      : ``
                                                                    }`}</div>
                                                                </div>
                                                              </Col>
                                                              <Col className="col-md-4 d-flex  justify-content-center text-center">
                                                                <div className="d-flex flex-column">
                                                                  <div className="plus-jakarta-sans-font fw-medium text-5F6368 font-14">
                                                                    Time
                                                                  </div>
                                                                  <div className="bebas-neue-font font-18">
                                                                    {
                                                                      e?.timeDuration
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                      </Col>
                                                    </>
                                                  );
                                                })}
                                            </>
                                          )}
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <>
                                    <div className="empty-state__content p-2">
                                      <div className="empty-profile-state__icon p-1">
                                        <img
                                          src="/media/assets/events/no-found-gray.png"
                                          alt=""
                                          className="img-fluid"
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="p-4 fs-6 fw-500">
                                        No record found.
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {activeTab === "eventDetails" && (
                        <>
                          {showEventDetails && (
                            <EventDetailsCard
                              getEventDetailQuery={getEventDetailQuery}
                              slug={slug}
                              showOtherDetails={showOtherDetails}
                              isjoined={isjoined}
                            />
                          )}
                          {showEventDescription && (
                            <EventDescriptionCard
                              getEventDetailQuery={getEventDetailQuery}
                              showOtherDetails={showOtherDetails}
                              isFree={isFree}
                              eventID={eventID}
                              isjoined={isjoined}
                              isPublic={true}
                            />
                          )}
                        </>
                      )}
                      {activeTab === "activity" && showActivity && (
                        <div className="px-3 mt-3">
                          <Row className="bg-F3F3F3 p-4  rounded-4">
                            <Col sm={6} className="col-md-12 border-bottom">
                              <div className="row card-title mb-0 h5">
                                <div className="d-flex gap-3 align-items-center mb-2">
                                  <img src={three_star} alt="start" loading="lazy" />
                                  <span className="card-title mb-0 plus-jakarta-sans-font  font-18 fw-bold">
                                    Automatically tracked event/challenge
                                    activity
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col sm={6} className="col-md-12">
                              <>
                                {getActivityquery?.data?.result?.journal
                                  ?.length > 0 ? (
                                  getActivityquery?.data?.result?.journal?.map(
                                    (history: any, index: number) => {
                                      return (
                                        <>
                                          {history?.activity?.length > 0 && (
                                            <>
                                              {history?.activity
                                                ?.slice(1, 3)
                                                ?.map((e: any) => {
                                                  return (
                                                    <>
                                                      <Col
                                                        lg={12}
                                                        className="col-md-12 mb-3"
                                                      >
                                                        <Row
                                                          className="my-2 cursor-pointer"
                                                          onClick={() =>
                                                            ButtonClickHandler(
                                                              e.id,
                                                              "Location"
                                                            )
                                                          }
                                                        >
                                                          <Col
                                                            sm={6}
                                                            className="col-md-7"
                                                          >
                                                            <Row>
                                                              <Col className="col-md-2">
                                                                <img
                                                                  src={
                                                                    e?.eventTypeName ===
                                                                      "Walking"
                                                                      ? walking
                                                                      : e?.eventTypeName ===
                                                                        "Cycling"
                                                                        ? cycling
                                                                        : e?.eventTypeName ===
                                                                        "Running" &&
                                                                        running
                                                                  }
                                                                  alt="eventType"
                                                                  width={50}
                                                                  loading="lazy"
                                                                />
                                                              </Col>
                                                              <Col className="col-md-5 d-flex justify-content-start align-items-center">
                                                                <span className="fw-bolder fs-6 plus-jakarta-sans-font text-color-black">
                                                                  {getEventsessionTime(
                                                                    e?.startTime,
                                                                    e?.eventTypeName
                                                                  )}
                                                                </span>
                                                              </Col>
                                                              <Col className="col-md-5 d-md-block d-none d-md-flex justify-content-center align-items-center">
                                                                <span className="plus-jakarta-sans-font fw-bolder fs-6 text-capitalize text-color-black">
                                                                  {
                                                                    e?.eventTypeName
                                                                  }
                                                                </span>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          <Col
                                                            sm={6}
                                                            className="col-md-5"
                                                          >
                                                            <Row>
                                                              <Col className="col-md-4 d-flex justify-content-center text-center">
                                                                <div className="d-flex flex-column">
                                                                  <div className="plus-jakarta-sans-font fw-medium text-5F6368 font-14">
                                                                    Distance
                                                                  </div>
                                                                  <div className="bebas-neue-font font-18">{`${GetKilometers(
                                                                    e?.distance
                                                                  )} KM`}</div>
                                                                </div>
                                                              </Col>
                                                              <Col className="col-md-4 d-flex  justify-content-center border-start border-end text-center">
                                                                <div className="d-flex flex-column">
                                                                  <div className="plus-jakarta-sans-font fw-medium text-5F6368 font-14">
                                                                    Pace
                                                                  </div>
                                                                  <div className="bebas-neue-font font-18">{`${e?.speed
                                                                    } ${e?.speed > 0
                                                                      ? `/KM`
                                                                      : ``
                                                                    }`}</div>
                                                                </div>
                                                              </Col>
                                                              <Col className="col-md-4 d-flex  justify-content-center text-center">
                                                                <div className="d-flex flex-column">
                                                                  <div className="plus-jakarta-sans-font fw-medium text-5F6368 font-14">
                                                                    Time
                                                                  </div>
                                                                  <div className="bebas-neue-font font-18">
                                                                    {
                                                                      e?.timeDuration
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                      </Col>
                                                    </>
                                                  );
                                                })}
                                            </>
                                          )}
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <>
                                    <div className="empty-state__content p-2">
                                      <div className="empty-profile-state__icon p-1">
                                        <img
                                          src="/media/assets/events/no-found-gray.png"
                                          alt="not-found"
                                          className="img-fluid"
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="p-4 fs-6 fw-500">
                                        No record found.
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
                </>
                <>
                  {/* {showGoogleMap && (
                    <GoogleMap
                      getEventDetailQuery={getEventDetailQuery}
                      showEventDescription={showEventDescription}
                      showGoogleMap={showGoogleMap}
                      showOtherDetails={showOtherDetails}
                      userActivitySession={userActivitySession}
                      isOpenModal={isOpenModal}
                    />
                  )} */}
                </>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-footer-light mt-5">
          <div className="container">
            <div className="px-md-5 pb-md-2 p-0">
              <div className="row">
                <div className="col-12">
                  <h1 className="fw-600 py-5 text-center mb-0 text-uppercase font-40">
                    upcoming events
                  </h1>
                </div>
                <div className="col-12">
                  {getUpcomingEventQuery?.data?.result?.length > 0 ? (
                    <CardSlide
                      getUpcomingEventQuery={getUpcomingEventQuery}
                      pageName={"upcomingEvent"}
                      isdetailpage={false}
                    />
                  ) : (
                    <BlankPage />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-background ">
          <DownloadSection />
        </section>
      </main>
      <Modal
        show={isOpenModal}
        onHide={() => {
          setIsOpenModal(false);
        }}
        backdrop="static"
        size="xl"
        keyboard={false}
      >
        <div className="modal-content">
          <Modal.Header closeButton className="modal-header">
            <h2 className="fw-bold">User Activity Detail</h2>
          </Modal.Header>
          <Modal.Body className="modal-body scroll-y scroll-x">
            <div
              style={{
                height: "75vh",
                width: "100%",
                float: "left",
                backgroundColor: "#F4A460",
                position: "relative",
              }}
            >
              <MapComponent userActivitySession={userActivitySession} />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default EventDetails;
