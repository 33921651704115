import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import TransactionHistory from "../TransactionHistory/TransactionHistory";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { USER_DEFAULT_IMAGE_PATH } from "../../shared/constants";
import {
  GetUserProfile,
  Getallparticipatedevents,
} from "../../services/Web.type";
import { toAbsoluteUrl } from "../../helper/AssetHelpers";
import clsx from "clsx";
import { UserModel } from "../../module/auth/core/_models";
import { GetInitailCharaterClass } from "../../services/utility";
import moment from "moment";
import ParticipatedEventCards from "../../Common/Card/ParticipatedEventCards";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BlankPage from "../../Common/BlankData/BlankPage";
import Skeleton from "react-loading-skeleton";

const Profile = () => {
  const getUserQuery: any = usePostQuery("getUser", GetUserProfile);
  const [userDetail, setUserDetail] = useState<UserModel>();
  const [, setProfileImageUrl] = useState<any>(USER_DEFAULT_IMAGE_PATH);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const navigate = useNavigate();
  const [eventsList, setEventsList] = useState([]);
  useEffect(() => {
    getUserQuery.mutate();
  }, []);

  const getallparticipatedevents = usePostQuery(
    "getallparticipatedevents",
    Getallparticipatedevents,
    {}
  );

  useEffect(() => {
    if (getUserQuery.isSuccess) {
      const getUser: UserModel = getUserQuery?.data?.result;
      if (getUser != null && getUser.gender != null) {
        if (getUser.gender === "M") {
          getUser.gender = "Male";
        } else if (getUser.gender === "F") {
          getUser.gender = "Female";
        } else {
          getUser.gender = "-";
        }
      }
      setUserDetail(getUser);

      if (
        getUser != null &&
        getUser.image != null &&
        getUser.image !== undefined &&
        getUser.image !== ""
      ) {
        var profileImageUrl = getUser.image;
        setProfileImageUrl(profileImageUrl);
      } else {
        setProfileImageUrl(USER_DEFAULT_IMAGE_PATH);
      }
    }
  }, [getUserQuery?.isSuccess]);

  useEffect(() => {
    getallparticipatedevents.mutate({
      search: "",
      eventTypeID: [],
      page: page,
      limit: limit,
      isGetAll: false,
    });
  }, []);

  useEffect(() => {
    if (getallparticipatedevents?.isSuccess) {
      setEventsList(getallparticipatedevents?.data?.result);
    }
  }, [getallparticipatedevents?.isSuccess]);

  const handleClick = () => {
    navigate("/participations");
  };

  const onEditProfile = () => {
    navigate("/editprofile");
  };

  const userAddress =
    ((userDetail?.address ? userDetail.address + ", " : "") +
      (userDetail?.landmark ? userDetail.landmark + ", " : "") +
      (userDetail?.city ? userDetail.city + "-" : "") +
      (userDetail?.pincode ? userDetail.pincode + ", " : "") +
      (userDetail?.state ?? "")).trim();

  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-1 p-0">
                  <PageNavigation currentPage={"Profile"} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mb-2">
              <div className="col-12">
                <div className="px-lg-5 px-lg-4 pt-lg-4 pb-lg-2">
                  <div className="row">
                    <div className="col-12">
                      <div className="card bg-F6F6F6 border-0 rounded-4">
                        <div className="row p-lg-4">
                          <div className="col-12">
                            <div className="row p-30">
                              <div className=" col-md-3 ">
                                {userDetail ? (
                                  userDetail?.image ? (
                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                      <img
                                        src={userDetail?.image}
                                        alt="Fit Event"
                                        onError={(ele) => {
                                          ele.currentTarget.src = toAbsoluteUrl(
                                            USER_DEFAULT_IMAGE_PATH
                                          );
                                        }}
                                        width={150}
                                        height={150}
                                        className="rounded-circle"
                                        loading="lazy"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                        fontSize: "70px",
                                      }}
                                      className=" rounded-circle d-flex align-items-center justify-content-center bg-white"
                                    >
                                      <span
                                        className={`${clsx(
                                          "fs-2x",
                                          "fw-semibold",
                                          "bg-light-info",
                                          GetInitailCharaterClass(
                                            userDetail?.firstName
                                              ? userDetail?.firstName
                                                .charAt(0)
                                                .toUpperCase()
                                              : ""
                                          )
                                        )}`}
                                      >
                                        {userDetail?.firstName
                                          .charAt(0)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                  )
                                ) : (
                                  <Skeleton
                                    width={150}
                                    height={150}
                                    circle={true}
                                  />
                                )}
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex flex-column">
                                  <span className="fw-500 font-35 text-uppercase">
                                    {userDetail ? (
                                      `${userDetail.firstName} ${userDetail.surname}`
                                    ) : (
                                      <Skeleton
                                        width={150}
                                        height={24}
                                      />
                                    )}
                                  </span>
                                  <span className="fw-400 font-24 text-5F6368">
                                    {userDetail ? (
                                      (userDetail?.mobile ? (`+91  ${userDetail.mobile}`) : (`NA`))
                                    ) : (
                                      <Skeleton
                                        width={150}
                                        height={24}
                                      />
                                    )}
                                  </span>
                                </div>
                                <div className="pt-4">
                                  <button
                                    className="border rounded-pill  px-3 py-2 btnNavLink default-background-color fw-600 font-16 text-black"
                                    onClick={onEditProfile}
                                  >
                                    <Icon
                                      icon="ic:outline-mode-edit"
                                      height={19}
                                    />
                                    <span className="ps-2 text-uppercase">Edit Profile</span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-5 d-flex flex-column pt-4">
                                <div className="row">
                                  <div className="col-md-12 mb-2">
                                    <span className="text-uppercase font-24 fw-500">
                                      Address Details
                                    </span>
                                  </div>
                                  <div className="col-md-10">
                                    <span className="text-secondary fw-400  font-16">
                                      {userDetail ? (
                                        userAddress ? (
                                          `${userAddress}`
                                        ) : (
                                          "NA"
                                        )
                                      ) : (
                                        <Skeleton
                                          width={150}
                                          height={24}
                                        />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                <div className="px-lg-5">
                  <div className="row">
                    <div className="col-md-6 py-2">
                      <div className="card bg-F6F6F6 border-0 p-30 h-100 rounded-4">
                        <div className="row">
                          <div className="col-12">
                            <h3 className="fw-500 text-uppercase font-24">
                              About Me
                            </h3>
                            <div className="col-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="d-flex flex-column">
                                    <span className="fw-400 text-5F6368 font-18">
                                      Gender
                                    </span>
                                    <span className="bebas-neue-font font-22">
                                      {userDetail ? (
                                        userDetail?.gender ? (
                                          <span>{userDetail?.gender}</span>
                                        ) : (
                                          <span>NA</span>
                                        )
                                      ) : (
                                        <Skeleton width={60} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <span className="fw-400 text-5F6368 font-18 font-18">
                                    Birthdate
                                  </span>
                                  <div className="d-flex flex-column">
                                    <span className="bebas-neue-font font-22 text-uppercase">
                                      {userDetail ? (
                                        userDetail?.dob ? (
                                          <span>
                                            {moment(userDetail?.dob).format(
                                              "DD MMMM, yyyy"
                                            )}
                                          </span>
                                        ) : (
                                          <span>NA</span>
                                        )
                                      ) : (
                                        <Skeleton width={60} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="d-flex flex-column">
                                    <span className="fw-400 text-5F6368 font-18 font-18">
                                      Weight
                                    </span>
                                    <span className="bebas-neue-font font-22">
                                      {userDetail ? (
                                        userDetail?.weight ? (
                                          <span>{userDetail?.weight} kg</span>
                                        ) : (
                                          <span>NA</span>
                                        )
                                      ) : (
                                        <Skeleton width={60} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="d-flex flex-column">
                                    <span className="fw-400 text-5F6368 font-18 font-18">
                                      Height
                                    </span>
                                    <span className="bebas-neue-font font-22">
                                      {userDetail ? (
                                        userDetail?.height ? (
                                          <span>{userDetail?.height} cm</span>
                                        ) : (
                                          <span>NA</span>
                                        )
                                      ) : (
                                        <Skeleton width={60} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 py-2">
                      <TransactionHistory page={false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-md-5 pb-md-4  p-0 ">
              <span className="text-center text-black text-uppercase bebas-neue-font font-45">
                MY EVENTS
              </span>
            </div>
            <div className="row px-md-5 pb-md-1 p-0">
              {eventsList?.map((item: any) => (
                <ParticipatedEventCards key={item.id} item={item} />
              ))}
              {eventsList?.length === 0 && (
                <BlankPage />
              )}
              {eventsList?.length >= 3 && (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <Button
                    className="border rounded-pill fs-5 px-5 py-2 btnNavLink  btn-getapp text-dark fw-500 btn btn-primary"
                    onClick={handleClick}
                  >
                    View All
                  </Button>
                </div>
              )}

            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Profile;
